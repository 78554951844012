import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row, Select, Typography, Skeleton } from "antd";
import "./styles/home.css";
import { headerTitles } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import CountUp from "react-countup";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/usePayApis";
import { getCompanyList } from "../../actions/clients/clients";
import search1 from "../../assets/icons/search.svg";
import moment from "moment";

var Highcharts = require("highcharts");
const getDataValue = (data, key, status, valueType) => {
  const item = data?.find((item) => item[key] === status);
  if (item) {
    if (valueType === "count" && item.count) {
      return parseInt(item.count, 10);
    } else if (valueType === "sum" && item.sum) {
      return parseFloat(item.sum);
    } else if (
      valueType === "total_available_amount" &&
      item.total_available_amount
    ) {
      return parseInt(item.total_available_amount, 10);
    }
  }
  return 0;
};
const Dashboard = () => {
  const { Title } = Typography;
  const {
    onCall: DashboardSummary,
    data: dashboardData,
    loading: dLoading,
  } = usePayAxios({ api: apis?.getDashboardSummary, method: "get" });
  const {
    onCall: tpDashboardSummary,
    data: tpdashboardData,
    loading: tpLoading,
  } = usePayAxios({
    api: apis?.getDashboardSummaryforThirdParty,
    method: "get",
  });
  const {
    onCall: creidtSummary,
    data: creditData,
    loading: cLoading,
  } = usePayAxios({ api: apis?.getAPICreditsByType, method: "get" });
  const {
    onCall: transcationStats,
    data: transcationData,
    loading: tLoading,
  } = usePayAxios({ api: apis?.getTranscationStats, method: "get" });
  const {
    onCall: TopClients,
    data: topClientsData,
    loading: topLoading,
  } = usePayAxios({ api: apis?.getTopClients, method: "get" });
  const searchRef = useRef();
  const client_data = useSelector(
    (state) => state?.client?.getClientList?.data?.data ?? []
  );
  const [search, setSearchKey] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    DashboardSummary({});
    tpDashboardSummary({});
    creidtSummary({});
    dispatch(getCompanyList({}));
    transcationStats({
      params: {
        frequency: "MONTHLY",
      },
    });
    TopClients({});
    dispatch(
      headerTitles({
        title: "Dashboard",
      })
    );
  }, []);

  useEffect(() => {
    if (transcationData?.data?.length >= 0)
      Highcharts.chart("container", {
        colors: ["#1c83fc"],
        chart: {
          type: "column",
        },
        title: {
          text: " ",
        },
        xAxis: {
          categories: transcationData?.data?.map((ele) =>
            moment(ele.month, "YYYY-MM").format("MMM-YY")
          ),
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            return "<b>" + this.y + `</b>`;
          },
        },
        series: [
          {
            name: "Clients",
            data: transcationData?.data?.length
              ? transcationData?.data?.map((ele) => [
                  ele?.month,
                  parseInt(ele?.total_credits_consumed),
                ])
              : [],
          },
        ],
      });
  }, [transcationData]);

  useEffect(() => {
    if (creditData?.data?.length >= 0)
      Highcharts.chart("container1", {
        colors: ["#122549"],
        chart: {
          type: "column",
        },
        title: {
          text: " ",
        },
        xAxis: {
          categories: creditData?.data?.map((ele) => ele.api_full_name),
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            return "<b>" + this.y + `</b>`;
          },
        },
        series: [
          {
            name: "Credits",
            data: creditData?.data?.length
              ? creditData?.data?.map((ele) => [
                  ele?.api_full_name,
                  parseInt(ele?.count),
                ])
              : [],
          },
        ],
      });
  }, [creditData]);

  const optionDonut2 = useMemo(
    () => ({
      colors: ["#2ea700", "#ed3b41"],
      title: {
        text: " ",
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + `</b>`;
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          startAngle: 0,
          endAngle: 360,
          center: ["50%", "55%"],
          size: "100%",
          showInLegend: true,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + `${this?.point?.name} (${this?.y})` + `</b>`;
        },
      },
      series: [
        {
          type: "pie",
          // name: "donut_key",
          innerSize: "60%",
          data: tpdashboardData?.data
            ? Object.keys(tpdashboardData?.data)
                ?.filter((ele) => ele !== "total")
                ?.map((ele) => [ele, parseInt(tpdashboardData?.data?.[ele])])
            : [],
        },
      ],
    }),
    [tpdashboardData]
  );

  const optionDonut = useMemo(
    () => ({
      colors: ["#1c83fc", "#2ea700", "#ed3b41", "#4a4a4a", "#272727"],
      title: {
        text: " ",
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + `</b>`;
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          startAngle: 0,
          endAngle: 360,
          center: ["50%", "55%"],
          size: "100%",
          showInLegend: true,
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          return "<b>" + `${this?.point?.name} (${this?.y})` + `</b>`;
        },
      },
      series: [
        {
          type: "pie",
          // name: "donut_key",
          innerSize: "60%",
          data: topClientsData?.data?.length
            ? topClientsData?.data?.map((ele) => [
                ele?.name,
                parseInt(ele?.count),
              ])
            : [],
        },
      ],
    }),
    [topClientsData]
  );

  useEffect(() => {
    Highcharts?.chart("uniqueChartContainerID", optionDonut);
    Highcharts?.chart("uniqueChartContainerID1", optionDonut2);
  }, [optionDonut]);
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <div className="header">
        <div className="flex-between mb1">
          <Title level={4}>Summary</Title>
          <div className="d-flex">
            <Select
              style={{ width: "20rem" }}
              className="inputTag selectTag dashboardSearch"
              filterOption={filterOption}
              onSearch={(val) => {
                if (searchRef.current) clearTimeout(searchRef.current);
                setSearchKey(val);
                searchRef.current = setTimeout(() => {
                  dispatch(getCompanyList({ search_key: val }));
                }, 400);
              }}
              showSearch
              searchValue={search}
              onClear={() => {
                setSearchKey("");
              }}
              allowClear
              placeholder={"Search Client"}
              onChange={(val) => {
                DashboardSummary({
                  params: {
                    company_id: val,
                  },
                });
                creidtSummary({
                  params: {
                    company_id: val,
                  },
                });
                tpDashboardSummary({
                  params: {
                    company_id: val,
                  },
                });
                transcationStats({
                  params: {
                    company_id: val,
                    frequency: "MONTHLY",
                  },
                });
              }}
              options={
                client_data?.length
                  ? client_data?.map((ele) => ({
                      label: ele.name,
                      value: ele.id,
                    }))
                  : []
              }
            />
          </div>
        </div>

        <div className="header_cards">
          <Row gutter={[16]} style={{ marginLeft: "0rem" }}>
            <Col span={8} className="card d-flex">
              <div className="left_line"></div>
              <div className="text_div">
                <div className="flex-col">
                  <div className="card_circle v-center">
                    {" "}
                    <div></div> Total Clients
                  </div>
                  <div className="count">
                    {dLoading ? (
                      <Skeleton.Input />
                    ) : (
                      <CountUp
                        end={getDataValue(
                          dashboardData?.data?.clients,
                          "status",
                          "ALL",
                          "count"
                        )}
                        duration={4}
                      />
                    )}
                  </div>
                </div>
                <div className="flex-col mt1Half">
                  <div className="card_circle v-center">
                    {" "}
                    <div></div>Active Clients
                  </div>
                  <div className="count">
                    {dLoading ? (
                      <Skeleton.Input />
                    ) : (
                      <CountUp
                        end={getDataValue(
                          dashboardData?.data?.clients,
                          "status",
                          "active",
                          "count"
                        )}
                        duration={4}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={24} className="card d-flex mb1">
                  <div className="left_line active"></div>
                  <div className="text_div two_child">
                    <div className="flex-col">
                      <div className="card_circle v-center">
                        <div></div> Total Clients Users
                      </div>
                      <div className="count">
                        {dLoading ? (
                          <Skeleton.Input />
                        ) : (
                          <CountUp
                            end={getDataValue(
                              dashboardData?.data?.users,
                              "status",
                              "ALL",
                              "count"
                            )}
                            duration={4}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={24} className="card d-flex">
                  <div className="left_line active"></div>
                  <div className="text_div two_child">
                    <div className="card_circle v-center">
                      <div></div>API Credits Consumed
                    </div>
                    <div className="count">
                      {dLoading ? (
                        <Skeleton.Input />
                      ) : (
                        <CountUp
                          end={dashboardData?.data?.kyc_credits_consumed}
                          duration={4}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={24} className="card d-flex mb1">
                  <div className="left_line"></div>
                  <div className="text_div two_child">
                    <div className="flex-col">
                      <div className="card_circle v-center">
                        <div></div> Total Applicants
                      </div>
                      <div className="count">
                        {dLoading ? (
                          <Skeleton.Input />
                        ) : (
                          <CountUp
                            end={getDataValue(
                              dashboardData?.data?.total_applicants,
                              "status",
                              "ALL",
                              "count"
                            )}
                            duration={4}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={24} className="card d-flex">
                  <div className="left_line active"></div>
                  <div className="text_div two_child">
                    <div className="card_circle v-center">
                      <div></div>Third Party Agents
                    </div>
                    <div className="count">
                      {dLoading ? (
                        <Skeleton.Input />
                      ) : (
                        <CountUp
                          end={getDataValue(
                            dashboardData?.data?.third_party_agents,
                            "status",
                            "active",
                            "count"
                          )}
                          duration={4}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="graph mt2">
          <Row gutter={[16]}>
            <Col span={16}>
              <div className="card">
                <Title level={4}>Transactions summary</Title>
                <div
                  id="container"
                  className="dashChart"
                  style={{ width: "100%", height: "288px" }}
                ></div>
              </div>
            </Col>
            <Col span={8}>
              <div className="card">
                <Title level={4}>
                  Top 5 Clients{" "}
                  <span className="subTitel">(in terms of applicants)</span>
                </Title>
                <div
                  id="uniqueChartContainerID"
                  className="dashChart"
                  style={{ width: "100%", height: "288px" }}
                ></div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="graph mt2">
          <Row gutter={[16]}>
            <Col span={16}>
              <div className="card">
                <Title level={4}>Credits summary</Title>
                <div
                  id="container1"
                  className="dashChart"
                  style={{ width: "100%", height: "315px" }}
                ></div>
              </div>
            </Col>
            <Col span={8}>
              <div className="card">
                <Title level={4}>
                  Third Party Applications{" "}
                  <span className="subTitel">(in terms of applications)</span>
                </Title>
                <div
                  id="uniqueChartContainerID1"
                  className="dashChart"
                  style={{ width: "100%", height: "288px" }}
                ></div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
