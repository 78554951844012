import { Button, Modal, Space, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../assets/icons/delete.png";
import eye from "../../assets/icons/eye.png";
import {
  DELETE_PAGE_PROFILE,
  deletePageProfile,
  getAllPageProfiles,
  getPageProfileByID,
  getProfileSocre,
  headerTitles,
  UpdateProfileScore,
} from "../../actions/auth";
import TableComponent from "../Common/Table/Table";
import ProfileScoring from "./ProfileScoring";
import VIewProfile from "./VIewProfile";
const { Text, Title } = Typography;

const Profiles = ({ hasWriteAccess = true, company_id }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const compRes = useSelector(
    (state) => state?.client?.getStaffCmp?.data?.data
  );
  const profils = useSelector(
    (state) => state?.profiles?.getAllPageProfiles?.data?.data
  );
  const totalRecords = useSelector(
    (state) => state?.profiles?.getAllPageProfiles?.data?.total_count
  );

  const [selectedId, setSelectedId] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [viewPlan, setViewPlan] = React.useState(false);
  const updateScore = useSelector((state) => state?.profiles?.updateScore);
  const deleteProf = useSelector(
    (state) => state.profiles.deletePageProfile || {}
  );
  const [score, setScore] = React.useState("");

  useEffect(() => {
    if (company_id) {
      dispatch(
        headerTitles({
          title: "Profiles",
        })
      );
      dispatch(
        getAllPageProfiles({
          company_id: company_id,
        })
      );
    }
  }, [company_id]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      deleteProf;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch(
        getAllPageProfiles({
          company_id: company_id,
        })
      );
      dispatch({
        type: DELETE_PAGE_PROFILE.RESET,
      });
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProf]);

  React.useEffect(() => {
    const { data: { message: res_message = "", status = "" } = {} } =
      updateScore;
    if (status) {
      message.success(<span className="messageText">{res_message}</span>);
      setScore(false);
      dispatch(
        getAllPageProfiles({
          company_id: company_id,
        })
      );
      dispatch({
        type: UpdateProfileScore.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateScore]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setViewPlan(false);
    setScore("");
  };
  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Credits",
      dataIndex: "credits_remaining",
      key: "credits_remaining",
    },
  ];

  const tableAction = [
    {
      title: "Actions",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            title="View Plan"
            onClick={() => {
              setViewPlan(true);
              dispatch(getPageProfileByID({ id: record.id, company_id }));
            }}
          >
            <img src={eye} width={25} />
          </button>
          {(compRes?.kyc_status === "kyc_approved" ||
            compRes?.kyc_status === "kyc_auto_approved") &&
            compRes?.enabled && (
              <button
                className="link"
                onClick={() => {
                  setScore(record.id);
                  dispatch(getPageProfileByID({ id: record.id, company_id }));
                  dispatch(
                    getProfileSocre({ page_profile_id: record.id, company_id })
                  );
                }}
                disabled={!hasWriteAccess}
              >
                Update Score
              </button>
            )}
          {!record?.default && (
            <button
              onClick={() => {
                showDeleteModal(record.id);
              }}
              disabled={!hasWriteAccess}
            >
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />
            </button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="pt1">
      <Modal
        title={
          <>
            <img src={DeleteIcon} alt={"logo"} className="action-logo" />
            {"Delete"}
          </>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[]}
        className={"center-aligned-modal"}
      >
        <Text>{"Are you sure about deleting this plan"}</Text>
        <br />
        <div className="d-flex">
          <Button
            key="1"
            className="formButton mt1"
            onClick={() =>
              dispatch(
                deletePageProfile({
                  id: selectedId,
                  company_id,
                })
              )
            }
          >
            {"Delete"}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            onClick={() => handleCancel()}
          >
            {"Cancel"}
          </Button>
        </div>
      </Modal>
      <ProfileScoring
        visible={score}
        handleCancel={handleCancel}
        company_id={company_id}
      />

      <Modal
        title={"View Plan"}
        visible={viewPlan}
        onCancel={handleCancel}
        footer={null}
        className={"right-alinged-modal credit-modal view-plan"}
      >
        <VIewProfile />
      </Modal>

      <TableComponent
        rowKey="id"
        columns={[...columns, ...tableAction]}
        data={profils ?? []}
        hasCheckbox={false}
        className="settingsTable"
        pagination={{
          total: totalRecords || 0,
          current: page,
          onChange: (pagee) => {
            setPage(pagee);
            dispatch(
              getAllPageProfiles({ params: { page_number: pagee }, company_id })
            );
          },
        }}
      />
    </div>
  );
};

export default Profiles;
