import { message } from "antd";
import axios from "axios";

export const APP_BASE_URL = window.location.hostname?.includes("sandbox")
  ? "https://sandbox.zky.co.in"
  : "https://zky.purpleplumfi.com";

const endpointAdminPayApi = axios.create({
  baseURL: APP_BASE_URL,
});

endpointAdminPayApi.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

endpointAdminPayApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error?.response?.status === 422 &&
      error?.response.data?.message === "Signature has expired"
    ) {
      message.error(<span className="messageText">Session Expired.</span>);
      localStorage.clear();
      window.location.href = "/admin/login";
    }

    if (error?.response?.status === 401) {
      message.error(<span className="messageText">Session Expired.</span>);
      localStorage.clear();
      window.location.href = "/admin/login";
    }
    return Promise.reject(error);
  }
);

export default endpointAdminPayApi;
