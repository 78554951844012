import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../Common/Table/Table";

import { headerTitles } from "../../actions/auth";
import { getLeads } from "../../actions/clients/clients";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Phone",
    dataIndex: "phone_no",
    key: "phone_no",
  },
  {
    title: "Company Name",
    dataIndex: "company_name",
    key: "company_name",
  },
  {
    title: "Work email",
    dataIndex: "work_email",
    key: "work_email",
  },
  {
    title: "Description",
    dataIndex: "message",
    key: "message",
  },
];

const Leads = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState({});
  const data = useSelector((state) => state?.auth?.leads?.data?.data ?? []);
  const totalRecords = useSelector(
    (state) => state?.auth?.leads?.data?.total_count
  );
  const dataLoading = useSelector((state) => state?.auth?.leads?.loading);
  useEffect(() => {
    dispatch(
      getLeads({
        page: 1,
      })
    );
    dispatch(
      headerTitles({
        title: "Leads",
        description: "",
      })
    );
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    dispatch(
      getLeads({
        page: 1,
        search_key: value,
      })
    );
  };

  return (
    <>
      <Table
        columns={columns}
        data={Array.isArray(data) ? data : []}
        topFil={true}
        handleSearch={handleSearch}
        placeholder={"Search by Name"}
        pagination={{
          total: totalRecords || 0,
          onChange: (pagee) =>
            dispatch(getLeads({ params: { page_number: pagee }, ...filters })),
        }}
        loading={dataLoading}
      />
    </>
  );
};

export default Leads;
