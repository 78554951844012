import React, { useEffect, useRef } from "react";
import { useState } from "react";
import search from "../../assets/icons/search.svg";
import { Input } from "antd";

const SearchInput = (props) => {
  const [input, setInput] = useState("");
  const searchRef = useRef();

  return (
    <div className="search-input">
      <form onSubmit={(e) => {
        e.preventDefault();
        props.handleSearch && props.handleSearch(input);
      }}>
        <Input
          className='pp-input'
          id='search-input'
          placeholder={props.placeholder || "Search by ID, Name"}
          value={input}
          prefix={<img src={search} alt='' style={{ height: "14px" }} />}
          style={{
            backgroundColor: "#FAFAFA",
            border: "1px solid #EAEAEA",
            borderRadius: "5px",
            minWidth: "25rem",
          }}
          onChange={(e) => {
            setInput(e.target.value.trimStart())
            if (searchRef.current) {
              clearTimeout(searchRef.current)
            }
            searchRef.current = setTimeout(() => {
              props.handleSearch && props.handleSearch(e.target.value);
            }, 600);
          }}
        />
      </form>
    </div>);
};

export default SearchInput;
