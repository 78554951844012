import React from "react";
import "./auth.css";
import LoginForm from "./LoginForm";
import ResetPassword from "./ResetPassword";
import { Switch, Route } from "react-router-dom";
import RightImg from "../../assets/icons/right_img.png";
import Logo from "../../assets/icons/logo.png";

const Login = () => {
  return (
    <div className="login-page">
      <div className="login-container">
        <div className={`w50 login-right login-image-section`}>
          <div className="login-right-content login-image-container">
            <img src={Logo} alt={"logo"} className="logo mb2" />
            <div
              style={{
                width: "80%",
                margin: "auto",
              }}
            >
              <img src={RightImg} alt="right_img" />
            </div>
            <div className="flex-center flex-col right_content">
              <label>Streamline hiring with our</label>
              <span>background verification</span>
            </div>
          </div>
        </div>
        <div className="w50 login-left p-relative">
          <div className="login-right-section">
            <div className="login-right-container">
              <Switch>
                <Route exact path="/admin/login">
                  <LoginForm />
                </Route>
                <Route exact path="/admin/resetpassword/">
                  <ResetPassword />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
