import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import agent from "../../assets/icons/agent.svg";
import leads_svg from "../../assets/icons/Leads_icon.svg";
import app from "../../assets/icons/app.svg";
import ClientIcon from "../../assets/icons/Client.svg";
import DashboardIcon from "../../assets/icons/dashboard_icon.svg";
import StaffIcon from "../../assets/icons/Staff_icon.svg";
import UserIcon from "../../assets/icons/User_icon.svg";
import LOGO from "../../assets/icons/logo.png";
import { hasAccess, RouteConstants } from "../../utils";
import "./leftNavigation.css";

const LeftNavigation = (props) => {
  const history = useHistory();
  const { activeMenu, setActiveMenu } = props;
  const active = sessionStorage.getItem("activeDetails");

  useEffect(() => {
    if (active) {
      setActiveMenu(active);
    } else {
      setActiveMenu("dashboard");
    }
    // setActiveMenu(active)
  }, [active, activeMenu]);

  const menuItems = [
    {
      key: "dashboard",
      icon: <img src={DashboardIcon} alt={"logo"} className="menu-logo" />,
      text: "Dashboard",
      link: RouteConstants.DASHBOARD,
      access: true,
    },
    {
      key: "clients",
      icon: <img src={ClientIcon} alt={"logo"} className="menu-logo" />,
      text: "Clients",
      link: RouteConstants.CLIENTS,
      access: hasAccess("clients", "write") || hasAccess("clients", "read"),
    },
    {
      key: "users",
      icon: <img src={UserIcon} alt={"logo"} className="menu-logo" />,
      text: "Users",
      link: RouteConstants.USERS,
      access:
        hasAccess("permission_users", "write") ||
        hasAccess("permission_users", "read"),
    },
    {
      key: "staffUsers",
      icon: <img src={StaffIcon} alt={"logo"} className="menu-logo" />,
      text: "Staff Users",
      link: RouteConstants.STAFF_USERS,
      access:
        hasAccess("staff_users", "write") || hasAccess("staff_users", "read"),
    },
    {
      key: "leads",
      icon: <img src={leads_svg} alt={"logo"} className="menu-logo" />,
      text: "Leads",
      link: RouteConstants.LEADS,
      access: hasAccess("leads", "read"),
    },
    {
      key: "agents",
      icon: <img src={agent} alt={"logo"} className="menu-logo" />,
      text: "Third Party Agents",
      link: RouteConstants.AGENTS,
      access: hasAccess("agents", "write") || hasAccess("agents", "read"),
    },
    {
      key: "applications",
      icon: <img src={app} alt={"logo"} className="menu-logo" />,
      text: "Applications",
      link: "/admin/applications",
      access:
        hasAccess("applications", "write") || hasAccess("applications", "read"),
    },
  ];
  const menuItemsToRender = menuItems
    .filter((item) => item.access)
    .map((item) => (
      <div
        key={item.key}
        className={
          activeMenu === item.key ? `menu-item menu-selected ` : `menu-item`
        }
        onClick={() => {
          sessionStorage.setItem("activeDetails", item.key);
          history.push(item.link);
          setActiveMenu(item.key);
        }}
      >
        <span>
          {item.icon}
          <span className="nav-label">{item.text}</span>
        </span>
      </div>
    ));

  return (
    <div className="d-flex">
      <Sider trigger={null} className="left-menu">
        <div
          className="logo mt2 cursor"
          onClick={() => {
            history.push("/admin/dashboard");
            setActiveMenu("dashboard");
            sessionStorage.setItem("activeDetails", "dashboard");
          }}
        >
          <img src={LOGO} alt="" />
        </div>
        <Menu
          mode="inline"
          inlineCollapsed={props.state.collapsed}
          className="navigation-div"
          style={{ paddingTop: "0.8rem" }}
          selectedKeys={[props.state.selectedKey]}
          onClick={(info) => {
            sessionStorage.setItem("activeDetails", info.key);
            props.setState((s) => ({ ...s, selectedKey: info.key }));
          }}
        >
          {menuItemsToRender}
        </Menu>
      </Sider>
    </div>
  );
};

export default LeftNavigation;
