import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import calender from "../../assets/icons/calendar.png";
import close from "../../assets/icons/close.png";
import moment from "moment";
import Overview from "./Overview";
import ClientUsers from "./ClientUsers";
import Credits from "./Credits";
import { Button, Dropdown, Menu, message, Modal } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import AddCreditsModal from "../Profiles/CreditModal2";
import usePayAxios from "../../config/usePayApis";
import { apis } from "../../config/APIs";
import AddPlanModal from "./AddPlan";
import { getAllPageProfiles } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  getStaffCompanyDetails,
  StaffCompanyDetails,
} from "../../actions/clients/clients";
import TextArea from "antd/lib/input/TextArea";

const ClientDetails = () => {
  const [rejectText, setRejectText] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const dispatch = useDispatch();
  const {
    onCall: clientApproval,
    data: clientApprovalData,
    loading: approvalLoading,
    reset: approvalReset,
  } = usePayAxios({ api: apis?.onboardClientApproval, method: "post" });

  const {
    onCall: clientReject,
    data: clientRejectData,
    loading: rejectLoading,
    reset: rejectReset,
  } = usePayAxios({ api: apis?.onboardClientReject, method: "post" });

  const compRes = useSelector(
    (state) => state?.client?.getStaffCmp?.data?.data
  );
  const { onCall: getAllInvoices, data } = usePayAxios({
    api: apis.getAllInvoices,
    method: "post",
  });
  const history = useHistory();
  const location = useLocation();
  const clientDetails = location?.state?.record;
  const [tab, setTab] = useState("a");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [addPlan, setAddPlan] = React.useState(false);
  const items = [
    {
      key: "a",
      label: "Overview",
    },
    {
      key: "b",
      label: "Users",
    },
    {
      key: "ce",
      label: "Credits",
    },
    // {
    //   key: "c",
    //   label: "Settings",
    // },
  ];

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" disabled={!compRes?.enabled}>
        Edit Client
      </Menu.Item>
      <Menu.Item
        key="3"
        disabled={
          compRes?.kyc_status === "kyc_submitted" ||
          compRes?.kyc_status === "kyc_init" ||
          compRes?.kyc_status === "kyc_rejected" ||
          !compRes?.enabled
        }
      >
        Add Plan
      </Menu.Item>
    </Menu>
  );

  function handleMenuClick(e) {
    const agingMap = {
      1: onEditClient,
      3: onNewPlan,
    };

    agingMap?.[e?.key]?.();
  }

  const onNewPlan = () => {
    setAddPlan(true);
  };

  const onEditClient = () => {
    history.push({
      pathname: "/admin/clients/add-new-client/organisation-profile",
      state: {
        id: clientDetails?.id,
        country: clientDetails?.country,
      },
    });
  };

  const onAddCredits = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: StaffCompanyDetails.RESET,
      });
    };
  }, []);

  useEffect(() => {
    if (
      clientApprovalData?.error == false ||
      clientRejectData?.error == false
    ) {
      message.success(
        <span className="messageText">
          {clientApprovalData?.data?.message ?? clientRejectData?.data?.message}
        </span>
      );
      rejectReset();
      approvalReset();
      if (clientDetails?.id)
        dispatch(getStaffCompanyDetails(clientDetails?.id));
      setRejectModal(false);
    } else if (
      clientApprovalData?.error == true ||
      clientRejectData?.error == true
    ) {
      message.error(
        <span className="messageText">
          {clientApprovalData?.data?.message ?? clientRejectData?.data?.message}
        </span>
      );
      rejectReset();
      approvalReset();
    }
  }, [clientRejectData, clientApprovalData]);

  return (
    <div className="loan-details">
      {isModalVisible && (
        <AddCreditsModal
          visible={isModalVisible}
          handleCancel={() => {
            setIsModalVisible(false);
          }}
          company_id={location?.state?.record?.id}
          onSuccess={() => {
            getAllInvoices({
              params: {
                company_id: clientDetails?.id,
              },
            });
          }}
        />
      )}
      {addPlan && (
        <AddPlanModal
          visible={addPlan}
          handleCancel={() => {
            setAddPlan(false);
          }}
          company_id={location?.state?.record?.id}
          onSuccess={() => {
            getAllInvoices({
              params: {
                company_id: clientDetails?.id,
              },
            });
            dispatch(
              getAllPageProfiles({
                company_id: clientDetails?.id,
              })
            );
          }}
        />
      )}
      <Modal
        visible={rejectModal}
        title="Rejecting OnBoard Client"
        footer={[]}
        closable={false}
      >
        <TextArea
          name="address"
          value={rejectText}
          placeholder="Enter your reason"
          autoSize={{ minRows: 3, maxRows: 5 }}
          className="inputTag"
          onChange={(e) => {
            setRejectText(e?.target?.value);
          }}
        />
        <div className="d-flex">
          <button
            disabled={!rejectText}
            className="reject mt1"
            onClick={() => {
              clientReject({
                data: { company_id: compRes?.id, reject_reason: rejectText },
              });
            }}
          >
            Reject
          </button>
          <button
            className="cancel ml1 mt1"
            onClick={() => {
              setRejectModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="report-head flex-between">
        <div>
          <div className="title-sec">
            <h3>{clientDetails?.name}</h3>
            <span
              className={`status status_${
                clientDetails?.enabled ? "active" : "failed"
              }`}
            >
              {clientDetails?.enabled ? "Active" : "In-Active"}
            </span>
          </div>
          <p className="sub">
            <img src={calender} width={15} />
            <span>
              Created:{" "}
              {moment(clientDetails?.created_at).format("DD MMM, YYYY hh:mm a")}
            </span>
          </p>

          <span className="mt1">
            KYC Status:{" "}
            <span
              className={`status status_${
                compRes?.kyc_status === "kyc_auto_approved"
                  ? "auto"
                  : compRes?.kyc_status
              }`}
            >
              {compRes?.kyc_status?.replace(/_/g, " ")}
            </span>
          </span>
        </div>
        <div className="d-flex align-center">
          {compRes?.kyc_status === "kyc_submitted" ? (
            <div style={{ position: "relative", right: 25 }}>
              <button
                key="1"
                htmlType="button"
                className="approve"
                style={{ width: "100px" }}
                onClick={() => {
                  clientApproval({ data: { company_id: compRes?.id } });
                }}
              >
                Approve
              </button>
              <button
                className="reject ml1"
                onClick={() => {
                  setRejectModal(true);
                }}
                style={{ width: "100px" }}
              >
                Reject
              </button>
            </div>
          ) : null}
          <div
            className="mr1"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <button
              className={`pp-button`}
              // overlay={menu}
              onClick={() => {
                onAddCredits();
              }}
              disabled={
                compRes?.kyc_status === "kyc_submitted" ||
                compRes?.kyc_status === "kyc_init" ||
                !compRes?.enabled ||
                compRes?.kyc_status === "kyc_rejected"
              }
            >
              Add Credits
            </button>
            <Dropdown.Button overlay={menu} className="c-more" />
          </div>
          <div className="cursor">
            <img
              src={close}
              width={20}
              className="close"
              onClick={() => {
                history.push("/admin/clients");
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="tabs-sec">
          <ul>
            {items.map(
              (ele) =>
                ele?.label && (
                  <li
                    onClick={() => setTab(ele.key?.toLowerCase())}
                    className={`${
                      tab.toLowerCase() === ele.key?.toLowerCase()
                        ? "active"
                        : ""
                    }`}
                  >
                    {ele.label}
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      {tab === "a" && <Overview />}
      {tab === "b" && <ClientUsers company_id={clientDetails?.id} />}
      {tab === "ce" && (
        <Credits getAllInvoices={getAllInvoices} invoices={data} />
      )}
    </div>
  );
};

export default ClientDetails;
