import { Modal, Space, Select, Button, message, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/auth";
import {
  AddAgent,
  agentsList,
  applicationsList,
} from "../../actions/employees/employees";
import { hasAccess } from "../../utils";
import Table from "../Common/Table/Table";
import usePayAxios from "../../config/usePayApis";
import { apis } from "../../config/APIs";
import moment from "moment";

const Applications = () => {
  const [currntPage, setCurrentPage] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [headerMy, headerChange] = useState(false);
  const data = useSelector(
    (state) => state?.agents?.get_applications?.data?.data
  );

  const dataLoading = useSelector(
    (state) => state?.agents?.get_applications?.loading
  );
  const totalRecords = useSelector(
    (state) => state?.agents?.get_applications?.data?.total_count
  );
  const employeesResponse = useSelector(
    (state) => state?.agents?.get_agents?.data?.data
  );

  const {
    onCall: assignTP,
    data: assignData,
    reset,
  } = usePayAxios({
    api: apis.assignThirdPartyAgent,
    method: "post",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      agentsList({
        params: {
          pagination: false,
        },
      })
    );

    dispatch(
      headerTitles({
        title: "Third Party Applications",
      })
    );
    dispatch({
      type: AddAgent.RESET,
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (assignData?.error === false) {
      message.success(assignData?.message);
      dispatch(
        applicationsList({
          headerMy,
          params: {
            page_number: 1,
          },
        })
      );
      setIsModelVisible(false);
      setSelectedAssignee("");
      reset();
    } else if (assignData?.error === true) {
      message.error(assignData?.message);
      reset();
    }
  }, [assignData]);

  const columns = [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Applicant Name",
      dataIndex: "candidate_name",
      key: "candidate_name",
      render: (text) => text,
    },
    {
      title: "Application Number",
      dataIndex: "candidate_application_number",
      key: "candidate_application_number",
      render: (text) => text,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("DD-MM-YYYY, HH:mm A"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span className={`status status_${text}`}>{text}</span>,
    },
    hasAccess("staff_users", "write")
      ? {
          title: "Actions",
          dataIndex: "id",
          width: 150,
          key: "id",
          render: (text, record) =>
            record?.email !== localStorage.getItem("email") ? (
              <Space>
                <span
                  className="link"
                  onClick={() => {
                    setSelectedRecord(record);
                    setIsModelVisible(true);
                  }}
                >
                  Assign Agent
                </span>
              </Space>
            ) : null,
        }
      : {},
  ];
  const columns1 = [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
      render: (text) => text,
    },
    {
      title: "Applicant Name",
      dataIndex: "application_name",
      key: "application_name",
      render: (text) => text,
    },
    {
      title: "Application Number",
      dataIndex: "application_number",
      key: "application_number",
      render: (text) => text,
    },
    {
      title: "Assigned Date",
      dataIndex: "assigned_start_date_time",
      key: "assigned_start_date_time",
      render: (text) => moment(text).format("DD-MM-YYYY, HH:mm A"),
    },
    // {
    //   title: "Submitted Date",
    //   dataIndex: "assigned_completion_date_time",
    //   key: "assigned_completion_date_time",
    //   render: (text) =>
    //     text ? moment(text).format("DD-MM-YYYY, HH:mm A") : "-",
    // },
    {
      title: "Application Status",
      dataIndex: "application_status",
      key: "application_status",
      render: (text) => (
        <span className={`status status_${text?.toLowerCase()}`}>
          {text?.replace(/_/g, " ")}
        </span>
      ),
    },
  ];
  const renderDeleteOption = () => {
    const roles = employeesResponse?.map((ele) => ({
      label: ele.name,
      value: ele.id,
    }));
    return (
      <>
        <Form.Item label="Third Party Agents" required>
          <Select
            onChange={(e) => setSelectedAssignee(e)}
            options={roles}
            style={{ width: 300 }}
            value={selectedAssignee ? selectedAssignee : null}
            className="mb1"
            placeholder="Select Third Party Agent"
          />
        </Form.Item>
        <br />
        <div
          style={{
            display: "flex",
            marginTop: "17px",
          }}
        >
          <Button
            key="1"
            className="formButton"
            disabled={!selectedRecord?.id}
            onClick={() => {
              if (!selectedAssignee) {
                message.error("Please select agent");
                return;
              }
              assignTP({
                data: {
                  id: selectedRecord?.id,
                  candidate_application_id:
                    selectedRecord?.candidate_application_id,
                  assigned_to: selectedAssignee,
                },
              });
            }}
          >
            Assign
          </Button>
          <Button
            key="2"
            className="cancelButton ml1"
            onClick={() => setIsModelVisible(false)}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };
  return (
    <>
      <Modal
        title={"Assign User"}
        visible={isModelVisible}
        onCancel={() => {
          setIsModelVisible(false);
        }}
        footer={[]}
        className="center-aligned-modal"
      >
        {renderDeleteOption()}
      </Modal>
      <Table
        columns={headerMy ? columns1 : columns}
        placeholder={"Search by Applicant Name"}
        loading={dataLoading}
        data={Array.isArray(data) ? data : []}
        pagination={{
          total: totalRecords || 0,
          // current: currntPage,
          onChange: (pagee) => {
            dispatch(
              applicationsList({ headerMy, params: { page_number: pagee } })
            );
            setCurrentPage(pagee);
          },
        }}
        topFil={true}
        addBtn={true}
        handleSearch={(val) => {
          if (val) dispatch(applicationsList({ headerMy, search_key: val }));
          else dispatch(applicationsList({ headerMy }));
        }}
        header={
          <div className="table tabs mt1 mb1">
            <button
              className={`table tab ${headerMy === false ? "active" : ""}`}
              onClick={() => {
                headerChange(false);
                dispatch(
                  applicationsList({
                    params: {
                      page_number: 1,
                    },
                  })
                );
              }}
            >
              {"Unassigned"}
            </button>

            <button
              className={`table tab ${headerMy === true ? "active" : ""}`}
              onClick={() => {
                headerChange(true);
                dispatch(
                  applicationsList({
                    headerMy: true,
                    params: {
                      page_number: 1,
                    },
                  })
                );
              }}
            >
              {"Assigned"}
            </button>
          </div>
        }
      />
    </>
  );
};

export default Applications;
