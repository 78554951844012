import { saveAs } from "file-saver";
import json2csv from "json2csv";
import moment from "moment";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { headerTitles } from "../../actions/auth";
import { getCompanyList } from "../../actions/clients/clients";
import { UsersListExport, usersList } from "../../actions/employees/employees";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import Table from "../Common/Table/Table";

// const menu = (
//   <Menu>
//     {/* <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#send_invitation">
//         Send Invitation
//       </a>
//     </Menu.Item> */}
//     {/* <hr /> */}
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#auto_signup">
//         Auto Sign Up
//       </a>
//     </Menu.Item>
//     <hr />
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#generate_password">
//         Generate Password
//       </a>
//     </Menu.Item>
//   </Menu>
// );
const UserDashboard = () => {
  const dispatch = useDispatch();
  const users_list = useSelector((state) => state?.users?.list?.data?.data);
  const totalCount = useSelector((state) => state?.users?.list?.data);
  const users_list_export = useSelector((state) => state?.users?.export);
  const [filters, setFilters] = React.useState({});
  const [company, setCompany] = React.useState("");
  const [expLoading, setExpLoading] = React.useState(false);
  const client_data = useSelector((state) =>
    state?.client?.getClientList?.data?.data?.map(
      (ele) => ({ label: ele?.name, value: ele.id } ?? [])
    )
  );

  React.useEffect(() => {
    dispatch(usersList({ params: { page_number: 1 } }));
    dispatch(getCompanyList({ params: { page_number: 1 } }));
    dispatch(
      headerTitles({
        title: "Users",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (users_list_export?.success) {
      window.open(users_list_export?.data?.response["CSV File URL"], "_blank");
      dispatch({
        type: UsersListExport.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users_list_export]);
  const handleSearch = (value) => {
    dispatch(usersList({ ...filters, search_key: value }));
    setFilters({
      ...filters,
      search_key: value,
      // page: 1
    });
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      render: (text, data, idx) => {
        return <div className={`status-line`}>{idx + 1}</div>;
      },
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      className: "user-col",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
      className: "user-col",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "user-col",
    },
    {
      title: "Mobile",
      dataIndex: "primary_phone",
      key: "primary_phone",
      className: "user-col",
    },
    // {
    //   title: "Client",
    //   dataIndex: "client",
    //   className: "user-col",
    //   key: "client",
    // },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      className: "user-col",
      render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      className: "user-col",
      key: "last_login",
      render: (text) => (
        <div>{text ? moment(text).format("DD MMM YYYY") : "-"}</div>
      ),
    },
    {
      title: "Role",
      dataIndex: "roles",
      className: "user-col",
      key: "roles",
      render: (text) => <div>{text?.join(", ")}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "user-col",
      key: "status",
      render: (text) => (
        <div className={`status ${text === "deleted" ? "inactive" : text}`}>
          {text === "deleted" ? "In-Active" : text}
        </div>
      ),
    },
    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   fixed: "right",
    //   width: 100,
    //   key: "actions",
    //   render: () => (
    //     <div className="actionsCard">
    //       <Dropdown overlay={menu} placement="bottomRight" arrow>
    //         <div className="expandCardIconDiv">
    //           <DownSquareOutlined />
    //         </div>
    //       </Dropdown>
    //     </div>
    //   ),
    // },
  ];

  const topFilters = [
    {
      label: "Status",
      action: (value) => {
        const obj = {
          ...filters.filters,
        };

        if (filters.filters?.id) {
          obj.id = [filters.filters?.id];
        }
        if (value !== "all" && value !== undefined) {
          obj.status = [value];
        } else {
          delete obj.status;
          delete filters.filters;
        }

        const updatedFilters = {
          ...filters,
          filters: obj,
        };

        dispatch(usersList(updatedFilters));
        setFilters(updatedFilters);
      },
      type: "dropdown",
      options: [
        { label: "All", value: "all" },
        { label: "Active", value: "active" },
        { label: "In-Active", value: "deleted" },
      ],
      value: filters.filters?.status ? filters.filters?.status : "all",
    },
    {
      label: company
        ? client_data?.find((ele) => ele.value === company)?.label
        : "Company",
      actionSearch: (value) => {
        dispatch(getCompanyList({ search_key: value }));
      },
      action: (value) => {
        const newFilters = {
          ...filters,
          filters: {
            ...filters.filters,
            company_id: [value],
          },
        };
        if (newFilters.filters.company_id[0] === undefined) {
          delete newFilters.filters;
        }
        dispatch(usersList(newFilters));
        setFilters(newFilters);
        setCompany(value);
      },
      type: "dropdown",
      options: client_data ? client_data : [],
      searchOption: true,
      value: company ? company : null,
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        company={company}
        placeholder={"Search by Employee Name, Mobile, Email"}
        data={users_list?.length ? users_list : []}
        isTopFilter={true}
        handleSearch={handleSearch}
        topFilters={topFilters}
        pagination={{
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entires`;
          },
          total: totalCount?.total_count || 0,
          activePage: filters.page,
          onChange: useCallback(
            (page) => {
              dispatch(
                usersList({
                  ...filters,
                  params: { page_number: page },
                })
              );
              setFilters({
                ...filters,
                page,
              });
              // eslint-disable-next-line
            },
            [filters]
          ),
        }}
        // exportLoading={users_list_export?.loading}
        exportLoading={expLoading}
        loading={users_list?.loading}
        exportToExcel={() => {
          // dispatch(
          //   usersListExport({
          //     ...filters,
          //     // page: filters.page ? filters.page : 1,

          //   })
          // );
          setExpLoading(true);
          endpoint
            .post(apis.staffUserList + `?isCsv=true`, {
              // params: {
              //   isCsv: true
              // }
              ...filters,
            })
            .then((res) => {
              setExpLoading(false);
              console.log(res, "responseDetails=====>");
              let cli_data = res?.data?.data;
              let csv = json2csv.parse(
                cli_data?.map((ele) => {
                  let obj = {};
                  obj["Name"] = ele.name;
                  obj["Email"] = ele.email;
                  obj["Phone"] = ele.primary_phone;
                  obj["Employee ID"] = ele.emp_id;
                  obj["Company"] = ele.company;
                  obj["Roles"] = ele.roles?.join(", ");
                  obj["Status"] =
                    ele.status === "active" ? "Active" : "In Active";
                  obj["Last Login"] = moment(ele.last_login).format(
                    "DD-MM-YYYY, HH:mm A"
                  );
                  obj["Created Date"] = moment(ele.created_date).format(
                    "DD-MM-YYYY, HH:mm A"
                  );
                  obj["Updated Date"] = moment(ele.updated_at).format(
                    "DD-MM-YYYY, HH:mm A"
                  );
                  return obj;
                })
              );
              var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
              saveAs(blob, "Users.csv");
            });
        }}
      />
    </>
  );
};

export default withRouter(UserDashboard);
