import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import {
  Employees,
  AddEmployees,
  DeleteEmployees,
  EmployeesStatus,
  UsersList,
  UsersListExport,
  ClientListExport,
  StaffUsersList,
  AddStaffUser,
  StaffUser,
  StaffUserDelete,
  AddAgent,
  Agent,
  AgentList,
  ApplicationList,
} from "./employees";

function usersList(req) {
  return sagaFunctions(UsersList, "post", apis.staffUserList, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function staffUsersList(req) {
  return sagaFunctions(StaffUsersList, "post", apis.staffUser, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function addStaffUser(req) {
  return sagaFunctions(
    AddStaffUser,
    "post",
    req.payload?.id ? apis.staff_users_update : apis.createstaffUser,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function usersListExport(req) {
  return sagaFunctions(
    UsersListExport,
    "get",
    apis.staffUserExportList,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function clientListExport(req) {
  return sagaFunctions(
    ClientListExport,
    "get",
    apis.clientExportList,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function getEmployees(req) {
  return sagaFunctions(Employees, "get", apis.employees, req.payload, {
    "Content-Type": "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function staffUserShow(req) {
  return sagaFunctions(StaffUser, "get", apis.staffUserGet, req.payload)();
}

function updateEmployeesStatus(req) {
  return sagaFunctions(
    EmployeesStatus,
    "post",
    apis.employeesStatus,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function addEmployees(req) {
  return sagaFunctions(AddEmployees, "post", apis.employees, req.payload, {
    "Content-Type": "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function addAgent(req) {
  return sagaFunctions(
    AddAgent,
    "post",
    req.payload.id ? apis.updateAgent : apis.addAgent,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function deleteEmployees(req) {
  return sagaFunctions(
    DeleteEmployees,
    "post",
    apis.deleteEmployees,
    req.payload,
    {
      "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function deleteStaff(req) {
  return sagaFunctions(
    StaffUserDelete,
    "post",
    apis.staff_users_delete,
    req.payload,
    {
      // "Content-Type": "multipart/formdata",
      Authorization: localStorage.getItem("token"),
    }
  )();
}

function showAgent(req) {
  return sagaFunctions(Agent, "get", apis.showAgent, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function agentList(req) {
  return sagaFunctions(AgentList, "post", apis.getAgents, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function appList(req) {
  const isMy = req?.payload?.headerMy;
  delete req?.payload?.headerMy;
  return sagaFunctions(
    ApplicationList,
    "post",
    isMy ? apis.getAssignedThirdParty : apis.getAllThirdPartyVerifications,
    req.payload,
    {
      Authorization: localStorage.getItem("token"),
    }
  )();
}

export function* employeesWatcher() {
  yield takeLatest(ClientListExport.REQUEST, clientListExport);
  yield takeLatest(UsersListExport.REQUEST, usersListExport);
  yield takeLatest(UsersList.REQUEST, usersList);
  yield takeLatest(StaffUsersList.REQUEST, staffUsersList);
  yield takeLatest(StaffUser.REQUEST, staffUserShow);
  yield takeLatest(StaffUserDelete.REQUEST, deleteStaff);
  yield takeLatest(AddStaffUser.REQUEST, addStaffUser);
  yield takeLatest(Employees.REQUEST, getEmployees);
  yield takeLatest(EmployeesStatus.REQUEST, updateEmployeesStatus);
  yield takeLatest(AddEmployees.REQUEST, addEmployees);
  yield takeLatest(DeleteEmployees.REQUEST, deleteEmployees);
  yield takeLatest(AddAgent.REQUEST, addAgent);
  yield takeLatest(Agent.REQUEST, showAgent);
  yield takeLatest(AgentList.REQUEST, agentList);
  yield takeLatest(ApplicationList.REQUEST, appList);
}
