import { combineReducers } from "redux";
import {
  createPageProfileReducer,
  deletePageProfileReducer,
  get_profile_score_reducer,
  getAllPageProfilesReducer,
  getPageProfileByIDReducer,
  local_varaibles,
  login_reducer,
  logout_reducer,
  update_profile_score_reducer,
  updatePageProfileReducer,
} from "./actions/auth/reducer";
import {
  get_cards_reducer,
  get_card_imports_reducer,
  get_card_vendors_reducer,
  create_card_vendors_reducer,
  link_card_vendors_reducer,
  unlink_card_vendors_reducer,
  delete_card_vendors_reducer,
} from "./actions/cards";
import {
  add_categories_reducer,
  categories_reducer,
  categories_status_reducer,
  delete_categories_reducer,
} from "./actions/categories/reducer";
import {
  add_company_reducer,
  add_staff_company_reducer,
  base_currencies_reducer,
  upload_document_details,
  update_document_details,
  delete_document_details,
  company_list_reducer,
  company_detail_reducer,
  company_staff_list_reducer,
  enable_staff_company_reducer,
  get_staff_company_reducer,
  leads_reducer,
  onboard_preview_reducer,
  get_all_countries_reducer,
} from "./actions/clients/reducer";
import {
  add_agent_reducer,
  add_employees_reducer,
  add_staff_user_reducer,
  agent_list_reducer,
  application_list_reducer,
  client_list_export_reducer,
  delete_employees_reducer,
  employee_delete_reducer,
  employee_reducer,
  employees_reducer,
  employees_status_reducer,
  show_agent_reducer,
  staff_users_list_reducer,
  users_list_export_reducer,
  users_list_reducer,
} from "./actions/employees/reducer";

export default combineReducers({
  auth: combineReducers({
    login: login_reducer,
    logout: logout_reducer,
    leads: leads_reducer,
    localVariables: local_varaibles,
  }),
  // reports: combineReducers({ reports: reports_reducer }),
  categories: combineReducers({ categories: categories_reducer }),
  cards: combineReducers({
    get_cards: get_cards_reducer,
    card_imports: get_card_imports_reducer,
    get_card_vendors: get_card_vendors_reducer,
    create_card_vendors: create_card_vendors_reducer,
    link_card_vendors: link_card_vendors_reducer,
    unlink_card_vendors: unlink_card_vendors_reducer,
    delete_card_vendors: delete_card_vendors_reducer,
  }),
  categoriesStatus: combineReducers({
    categoriesStatus: categories_status_reducer,
  }),
  addCategories: combineReducers({ addCategories: add_categories_reducer }),
  deleteCategories: combineReducers({
    deleteCategories: delete_categories_reducer,
  }),

  employees: combineReducers({ employees: employees_reducer }),
  agents: combineReducers({
    get_agents: agent_list_reducer,
    show_agent: show_agent_reducer,
    add_agent: add_agent_reducer,
    get_applications: application_list_reducer,
  }),
  employeesStatus: combineReducers({
    employeesStatus: employees_status_reducer,
  }),
  addEmployees: combineReducers({ addEmployees: add_employees_reducer }),
  client: combineReducers({
    addCompanyList: add_company_reducer,
    getClientList: company_list_reducer,
    getCompanyDetailsList: company_detail_reducer,
    staffCompanyList: company_staff_list_reducer,
    addStaffCompany: add_staff_company_reducer,
    getStaffCmp: get_staff_company_reducer,
    enalbeCmp: enable_staff_company_reducer,
    export: client_list_export_reducer,
    currencies: base_currencies_reducer,
    uploadDocument: upload_document_details,
    updateDocument: update_document_details,
    deleteDocument: delete_document_details,
    onboardPreview: onboard_preview_reducer,
    getAllCountries: get_all_countries_reducer,
  }),
  staff_users: combineReducers({
    list: staff_users_list_reducer,
    add: add_staff_user_reducer,
    delete: employee_delete_reducer,
    emp: employee_reducer,
  }),
  users: combineReducers({
    list: users_list_reducer,
    export: users_list_export_reducer,
  }),
  deleteEmployees: combineReducers({
    deleteEmployees: delete_employees_reducer,
  }),
  clients: combineReducers({
    addCompany: add_company_reducer,
  }),
  profiles: combineReducers({
    createPageProfile: createPageProfileReducer,
    updatePageProfile: updatePageProfileReducer,
    getAllPageProfiles: getAllPageProfilesReducer,
    getPageProfileByID: getPageProfileByIDReducer,
    deletePageProfile: deletePageProfileReducer,
    updateScore: update_profile_score_reducer,
    getScore: get_profile_score_reducer,
  }),
});
