export const apis = {
  login: `/api/admin/v1/employee/login`,
  reports: `/api/reports/`,
  categories: `/api/admin/categories/`,
  categoriesStatus: `/api/admin/categories/change_status/`,
  deleteCategories: `/api/admin/categories/delete/`,
  deleteClient: `/api/admin/v1/superAdmin/deleteClient`,
  employees: `/api/admin/employees/`,
  companies: `/api/admin/companies/`,
  clientList: "/api/admin/v1/superAdmin/getAllClientDetails",
  leads: "api/admin/v1/superAdmin/getAllLeadsDetails",
  staffLogin: "/api/admin/v1/staff/login",
  staffCompanies: "/api/admin/v1/superAdmin/createClient",
  staffUpdateCompanies: "/api/admin/v1/superAdmin/updateClient",
  staffCompanyDetails: "/api/admin/v1/superAdmin/getClientDetailsByID",
  staffEnableCompany: "/api/admin/v1/superAdmin/enableClient",
  staffUserList: "/api/admin/v1/superAdmin/getAllUserDetails",
  staffUserExportList: "/api/staff/users/export_user_list_csv",
  clientExportList: "/api/staff/companies/export_company_list_csv",
  staffCards: "v1/admin/0/superAdmin/getAllCardsDetails",
  staffCardImports: "/api/staff/cards/imports",
  staffCardDownloadFile: "/api/staff/cards/download_file",
  staffLogout: "/api/admin/v1/staff/logout",
  staffUser: "/api/admin/v1/superAdmin/getAllStaffDetails",
  staffUserGet: "/api/admin/v1/superAdmin/getStaffDetailsByID",
  createstaffUser: "/api/admin/v1/superAdmin/createStaffUsers",
  companyDetails: "/api/admin/v1/superAdmin/getCompanyDetails",
  staff_users_delete: "/api/admin/v1/superAdmin/deleteStaffUserDetails",
  staff_users_update: "/api/admin/v1/superAdmin/updateStaffUserDetails",
  base_currencies: `/api/admin/v1/superAdmin/getAllCurrencies`,
  getOnboardPreview: "/api/admin/v1/kyc/getCompanyKycDetails",
  getAllCountriesApi: "/api/admin/v1/kyc/getKycCountries",
  staffPermissions: `/api/admin/v1/superAdmin/fetchPermissions`,
  resetPassword: "/api/admin/v1/employee/forgotPassword",
  card_vendors: "/api/admin/v1/superAdmin/getAllCardVendors",
  link_card_vendor: "/api/staff/card_vendors/link_card_vendor",
  unlink_card_vendor: "/api/staff/card_vendors/unlink_card_vendor",
  delete_vendor: "/api/staff/card_vendors/delete",
  getIfscDetails: "/api/admin/v1/superAdmin/getIfscDetails",

  //documentsApi
  createDocument: "/api/documents/v1/upload",
  getDocument: "/api/documents/v1/attachments",
  deleteDocument: "api/documents/v1/attachments",
  updateDocument: "/api/documents/v1/attachments",
  // Dashboard apis
  getDashboardSummary: "/api/admin/v1/dashboard/getDashboardSummary",
  getDashboardSummaryforThirdParty:
    "/api/admin/v1/dashboard/getDashboardSummaryforThirdParty",
  getAPICreditsByType: "/api/admin/v1/dashboard/getAPICreditsByType",
  getTranscationStats: "/api/admin/v1/dashboard/getTransactionStats",
  getTopClients: "/api/admin/v1/dashboard/getTopClients",

  //CardVendor apis
  createCardVendor: "/api/admin/v1/superAdmin/createCardVendor",
  onboardClientApproval: "/api/admin/v1/kyc/approveKycDetails",
  onboardClientReject: "/api/admin/v1/kyc/rejectKycDetails",
  // Agents
  addAgent: "/api/admin/v1/agent/createExternalAgent",
  getAgents: "/api/admin/v1/agent/getAllExternalAgents",
  showAgent: "/api/admin/v1/agent/getExternalAgentByID",
  updateAgent: "/api/admin/v1/agent/updateExternalAgent",
  getAllThirdPartyVerifications:
    "/api/admin/v1/agent/getAllThirdPartyVerifications",
  getAssignedThirdParty: "/api/admin/v1/agent/getAssignedThirdParty",
  assignThirdPartyAgent: "/api/admin/v1/agent/assignThirdPartyAgent",

  // create profile apis,
  createPageProfile: "/api/core/v1/admin/profile/createPageProfile",
  updatePageProfile: "/api/core/v1/admin/profile/updatePageProfile",
  getAllPageProfiles: "/api/core/v1/admin/profile/getAllPageProfiles",
  getPageProfileByID: "/api/core/v1/admin/profile/getPageProfileByID",
  deletePageProfile: "/api/core/v1/admin/profile/deletePageProfile",
  getScoresBasedOnProfile: "/api/core/v1/admin/profile/getScoresBasedOnProfile",
  updateScoresBasedOnProfile:
    "/api/core/v1/admin/profile/updateScoresBasedOnProfile",
  addCredits: "/api/core/v1/admin/payments/addCredits",
  // invoices
  getAllInvoices: "/api/core/v1/admin/invoice/getAllInvoices",
  recordPayment: "/api/core/v1/admin/receipts/recordPayment",
};
