import { ActionTypesFactory } from "../../utils";

export const Login = ActionTypesFactory("Login", "Auth");
export const Logout = ActionTypesFactory("Logout", "Auth");

// Create Profile
export const UpdateProfileScore = ActionTypesFactory(
  "Profile",
  "UpdateProfileScore"
);

export const GetProfileScore = ActionTypesFactory(
  "Profile",
  "GetProfileScore"
);
export const CREATE_PAGE_PROFILE = ActionTypesFactory(
  "CREATE_PAGE_PROFILE",
  "Auth"
);
export const UPDATE_PAGE_PROFILE = ActionTypesFactory(
  "UPDATE_PAGE_PROFILE",
  "Auth"
);
export const GET_ALL_PAGE_PROFILES = ActionTypesFactory(
  "GET_ALL_PAGE_PROFILES",
  "Auth"
);
export const GET_PAGE_PROFILE_BY_ID = ActionTypesFactory(
  "GET_PAGE_PROFILE_BY_ID",
  "Auth"
);
export const DELETE_PAGE_PROFILE = ActionTypesFactory(
  "DELETE_PAGE_PROFILE",
  "Auth"
);
export const HeaderTitles = "HEADER_TITLES";


export const getProfileSocre = (payload) => {
  return {
    type: GetProfileScore.REQUEST,
    payload,
  };
};

export const login = (payload) => {
  return {
    type: Login.REQUEST,
    payload,
  };
};

export const logout = () => {
  return {
    type: Logout.REQUEST,
  };
};

export const headerTitles = (payload) => {
  return {
    type: HeaderTitles,
    payload,
  };
};

export const updatePSocre = (payload) => {
  return {
    type: UpdateProfileScore.REQUEST,
    payload,
  };
};

export const createPageProfile = (data) => ({
  type: CREATE_PAGE_PROFILE.REQUEST,
  payload: data,
});

export const updatePageProfile = (data) => ({
  type: UPDATE_PAGE_PROFILE.REQUEST,
  payload: data,
});

export const getAllPageProfiles = (payload) => ({
  type: GET_ALL_PAGE_PROFILES.REQUEST,
  payload,
});

export const getPageProfileByID = (payload) => {
  console.log({ payload });
  return {
    type: GET_PAGE_PROFILE_BY_ID.REQUEST,
    payload,
  };
};

export const deletePageProfile = (payload) => ({
  type: DELETE_PAGE_PROFILE.REQUEST,
  payload,
});
