import { Checkbox, message } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CREATE_PAGE_PROFILE,
  UPDATE_PAGE_PROFILE,
  createPageProfile,
  getPageProfileByID,
  updatePageProfile,
} from "../../actions/auth";
import FormInput from "./FormInput";
import PlanComponent from "./Plan";

const CreateProfile = ({ company_id, handleCancel, onSuccess }) => {
  const dispatch = useDispatch();
  const [perms, setPerms] = useState([]);
  const profil = useSelector(
    (state) => state?.profiles?.getPageProfileByID?.data?.data
  );
  const create = useSelector((state) => state?.profiles?.createPageProfile);
  const update = useSelector((state) => state?.profiles?.updatePageProfile);
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      permissions: [],
    },
  });

  const commonList = [
    "Social Verification",
    "Email Social Verification",
    "Phone Social Information",
    "AML Person Screening",
    "Crime check",
  ];

  const extractedData = (data, ignoreList = commonList) =>
    Array.isArray(data)
      ? data.flatMap((item) => {
          const permissions = item.sections.flatMap((section) => {
            const p = section.section_page_fields.fields_list.flatMap(
              (sec) => ({
                permission_id: sec.id,
                permission_key: sec.permission_key,
                candidate_verification: ignoreList?.includes(sec.section_title)
                  ? false
                  : true,
                digital_verification: sec.digital_verification,
                external_verification: sec.external_verification,
                recruiter_verification: ignoreList?.includes(
                  section.section_title
                )
                  ? false
                  : true,
              })
            );
            p.push({
              permission_id: section.id,
              permission_key: section.permission_key,
              candidate_verification: ignoreList?.includes(
                section.section_title
              )
                ? false
                : true,
              digital_verification:
                section?.section_title === "Aadhaar Details" ||
                section?.section_title === "Face Match" ||
                section?.section_title === "PAN Details"
                  ? true
                  : location.state?.id
                  ? section.digital_verification
                  : false,
              external_verification: location.state?.id
                ? section.external_verification
                : false,
              recruiter_verification: ignoreList?.includes(
                section.section_title
              )
                ? false
                : true,
              price: section?.cost?.price,
              name: section?.section_title,
            });
            return p;
          });
          permissions?.push({
            permission_id: item.id,
            permission_key: item.permission_key,
            candidate_verification: ignoreList?.includes(item.title)
              ? false
              : true,
            digital_verification: false,
            external_verification: false,
            recruiter_verification: ignoreList?.includes(item.title)
              ? false
              : true,
          });
          return permissions;
        })
      : [];

  const onSubmit = (data) => {
    data.permissions = perms?.filter((ele) => ele.recruiter_verification);
    data.company_id = company_id;
    if (location?.state?.id) {
      data.id = location?.state?.id;
      dispatch(updatePageProfile(data));
    } else dispatch(createPageProfile(data));
  };

  React.useEffect(() => {
    const { data: { message: res_message = "", error = "" } = {} } = create;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch({
        type: CREATE_PAGE_PROFILE.RESET,
      });
      handleCancel();
      reset();
      onSuccess();
    }
    if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: CREATE_PAGE_PROFILE.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create]);

  React.useEffect(() => {
    const {
      data: { message: res_message = "", status = "", error = "" } = {},
    } = update;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UPDATE_PAGE_PROFILE.RESET,
      });
    }
    if (error) {
      message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: UPDATE_PAGE_PROFILE.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  useEffect(() => {
    if (location?.state?.id)
      dispatch(
        getPageProfileByID({
          id: location?.state?.id,
          company_id,
        })
      );
    else
      dispatch(
        getPageProfileByID({
          company_id,
        })
      );
  }, []);

  useEffect(() => {
    if (profil) {
      if (location?.state?.id) {
        setValue("title", profil?.title);
        setValue("description", profil?.description);
        if (Array.isArray(profil?.permissions))
          setPerms(
            extractedData(
              profil?.permissions?.filter(
                (perm) =>
                  perm?.title !== "Credit Score" &&
                  perm?.title !== "Income Tax Returns (ITR)"
              )
            )
          );
      } else if (Array.isArray(profil)) {
        setPerms(
          extractedData(
            profil?.filter(
              (perm) =>
                perm?.title !== "Credit Score" &&
                perm?.title !== "Income Tax Returns (ITR)"
            )
          )
        );
      }
    }
  }, [profil]);

  const onChange = (checked, permId, list, key, cost, title) => {
    let idx = perms?.findIndex((ele) => ele.permission_id === permId);
    perms[idx][key] = checked;
    if (key === "digital_verification") {
      if (checked) {
        perms[idx]["price"] = cost;
        perms[idx]["name"] = title;
      } else {
        perms[idx]["price"] = "";
        perms[idx]["name"] = "";
      }
      if (commonList?.includes(title)) {
        perms[idx]["recruiter_verification"] = checked;
      }
    } else if (key === "candidate_verification") {
      perms[idx]["recruiter_verification"] = checked;
      if (title === "Face Match") {
        perms[idx]["digital_verification"] = checked;
      } else if (!checked) {
        perms[idx]["digital_verification"] = checked;
        perms[idx]["external_verification"] = checked;
      }
    }
    if (list) {
      list.forEach((itm) => {
        let idx = perms?.findIndex((ele) => ele.permission_id === itm.id);
        perms[idx][key] = checked;
        if (key === "digital_verification") {
          if (checked) {
            perms[idx]["price"] = itm.cost;
            perms[idx]["name"] = itm.title;
          } else {
            perms[idx]["price"] = "";
            perms[idx]["name"] = "";
          }
          if (commonList?.includes(title)) {
            perms[idx]["recruiter_verification"] = checked;
          }
        } else if (key === "candidate_verification") {
          perms[idx]["recruiter_verification"] = checked;
          if (!checked) {
            perms[idx]["external_verification"] = checked;
            perms[idx]["digital_verification"] = checked;
            perms[idx]["price"] = "";
            perms[idx]["name"] = "";
          }
        }
      });
    }
    setPerms([...perms]);
  };

  const onChangePerm = (permId) => {
    let idx = perms?.findIndex((ele) => ele.permission_id === permId);
    perms[idx]["digital_verification"] = false;
    perms[idx]["price"] = "";
    perms[idx]["name"] = "";
    setPerms([...perms]);
  };

  const onError = () => {
    const errorElement = document.querySelector(`[class=errorMsg]`);
    if (errorElement) {
      errorElement.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  const prof_items = location?.state?.id ? profil?.permissions : profil;

  return (
    <div className="card p1">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <div className="credit-analysis w60">
            <div className="mb2">
              <div className="pp-form-item w50">
                <FormInput
                  control={control}
                  {...register("title", {
                    required: "Title is required",
                  })}
                  required
                  errors={errors}
                  errorMessage={errors?.title?.message}
                  label="Title"
                  placeholder="Enter"
                  disabled={location?.state?.view}
                />
              </div>
              <div className="pp-form-item w50 pt1">
                <FormInput
                  control={control}
                  {...register("description", {
                    required: "Description is required",
                  })}
                  required
                  errors={errors}
                  errorMessage={errors?.description?.message}
                  label="Description"
                  placeholder="Enter"
                  type={"textarea"}
                  disabled={location?.state?.view}
                />
              </div>
            </div>
            {Array.isArray(prof_items) &&
              prof_items
                ?.filter(
                  (perm) =>
                    perm?.title !== "Credit Score" &&
                    perm?.title !== "Income Tax Returns (ITR)"
                )
                ?.map((permission) => (
                  <>
                    <div className="header">
                      <div className="head-details">
                        <h3>{permission?.title}</h3>
                        {permission.description && (
                          <p>{permission.description}</p>
                        )}
                      </div>
                      <div
                        className="d-flex comp-sec"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <h3>Show to Applicant</h3>

                        {permission.title !== "Overview" &&
                          permission.title !== "Address Details" &&
                          permission.title !== "Signature" &&
                          permission.title !== "Reference Check" &&
                          permission.title !== "Education Details" && (
                            <h3>Digitally Verification</h3>
                          )}
                      </div>
                    </div>

                    {permission?.sections?.map((section) => (
                      <div className="permissions" key={section.id}>
                        <ul>
                          <li className="d-flex" key={section.id}>
                            <span className="label">
                              {section.section_title}
                            </span>

                            <span className="label-sm">
                              <Checkbox
                                checked={
                                  perms?.find(
                                    (ele) => ele.permission_id === section.id
                                  )?.candidate_verification
                                }
                                onChange={(e) => {
                                  onChange(
                                    e.target.checked,
                                    section.id,
                                    undefined,
                                    "candidate_verification",
                                    "",
                                    section?.section_title
                                  );
                                  const secs = permission?.sections
                                    ?.filter?.((ele) => ele.id !== section.id)
                                    ?.map(
                                      (ele) =>
                                        perms?.find(
                                          (pre) => pre.permission_id === ele.id
                                        )?.candidate_verification
                                    );
                                  secs.push(e.target.checked);
                                  if (secs.includes(true)) {
                                    onChange(
                                      true,
                                      permission.id,
                                      [],
                                      "candidate_verification"
                                    );
                                  } else {
                                    onChange(
                                      false,
                                      permission.id,
                                      [],
                                      "candidate_verification"
                                    );
                                  }
                                }}
                                disabled={
                                  permission.title === "Overview" ||
                                  commonList?.includes(section.section_title) ||
                                  section.section_order === 1 ||
                                  section.section_title === "PAN Details" ||
                                  permission.title === "Address Details" ||
                                  section.section_title === "Signature"
                                }
                              />
                            </span>
                            {permission.title !== "Overview" &&
                              permission.title !== "Address Details" &&
                              permission.title !== "Reference Check" &&
                              permission.title !== "Signature" &&
                              permission.title !== "Education Details" && (
                                <span className="label-sm">
                                  <Checkbox
                                    checked={
                                      perms?.find(
                                        (ele) =>
                                          ele.permission_id === section.id
                                      )?.digital_verification
                                    }
                                    onChange={(e) => {
                                      onChange(
                                        e.target.checked,
                                        section.id,
                                        section?.section_page_fields
                                          ?.fields_list,
                                        "digital_verification",
                                        section?.cost?.price,
                                        section?.section_title
                                      );
                                      const secs = permission?.sections
                                        ?.filter?.(
                                          (ele) => ele.id !== section.id
                                        )
                                        ?.map(
                                          (ele) =>
                                            perms?.find(
                                              (pre) =>
                                                pre.permission_id === ele.id
                                            )?.digital_verification
                                        );
                                      secs.push(e.target.checked);

                                      if (secs.includes(true)) {
                                        onChange(
                                          true,
                                          permission.id,
                                          [],
                                          "digital_verification",
                                          undefined,
                                          section?.section_title
                                        );
                                      } else {
                                        onChange(
                                          false,
                                          permission.id,
                                          [],
                                          "digital_verification",
                                          undefined,
                                          section?.section_title
                                        );
                                      }
                                    }}
                                    disabled={
                                      (!perms?.find(
                                        (ele) =>
                                          ele.permission_id === section.id
                                      )?.candidate_verification &&
                                        !commonList?.includes(
                                          section?.section_title
                                        )) ||
                                      section.section_order === 1 ||
                                      section.section_title === "PAN Details" ||
                                      section.section_title === "Face Match"
                                    }
                                  />
                                </span>
                              )}
                          </li>
                        </ul>
                      </div>
                    ))}
                  </>
                ))}
            {Array.isArray(prof_items) &&
              prof_items
                ?.filter(
                  (ele) =>
                    ele.title === "Address Details" ||
                    ele.title === "Education Details"
                )
                ?.map((permission, i) => (
                  <>
                    {i === 0 && (
                      <div className="header">
                        <div className="head-details">
                          <h3>Third Party Verifications</h3>
                        </div>
                        <div
                          className="d-flex comp-sec"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <h3>
                            {/* <Checkbox
                              checked={
                                perms?.find(
                                  (ele) => ele.permission_id === permission.id
                                )?.external_verification
                              }
                              onChange={(e) => {
                                onChange(
                                  e.target.checked,
                                  permission.id,
                                  extractedData2(permission?.sections),
                                  "external_verification"
                                );
                                onChange(
                                  e.target.checked,
                                  tp_sections?.[0]?.id,
                                  extractedData2(tp_sections?.[0]?.sections),
                                  "external_verification"
                                );
                              }}
                            />{" "} */}
                            Send To Third Party
                          </h3>
                        </div>
                      </div>
                    )}

                    {permission?.sections?.map((section) => (
                      <div className="permissions" key={section.id}>
                        <ul>
                          <li className="d-flex" key={section.id}>
                            <span className="label">
                              {section.section_title}
                            </span>

                            <span className="label-sm">
                              <Checkbox
                                checked={
                                  perms?.find(
                                    (ele) => ele.permission_id === section.id
                                  )?.external_verification
                                }
                                onChange={(e) => {
                                  onChange(
                                    e.target.checked,
                                    section.id,
                                    undefined,
                                    "external_verification"
                                  );
                                  const secs = permission?.sections
                                    ?.filter?.((ele) => ele.id !== section.id)
                                    ?.map(
                                      (ele) =>
                                        perms?.find(
                                          (pre) => pre.permission_id === ele.id
                                        )?.external_verification
                                    );
                                  secs.push(e.target.checked);
                                  if (secs.includes(true)) {
                                    onChange(
                                      true,
                                      permission.id,
                                      [],
                                      "external_verification"
                                    );
                                  } else {
                                    onChange(
                                      false,
                                      permission.id,
                                      [],
                                      "external_verification"
                                    );
                                  }
                                }}
                                disabled={
                                  !perms?.find(
                                    (ele) => ele.permission_id === section.id
                                  )?.candidate_verification
                                }
                              />
                            </span>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </>
                ))}
          </div>
          <div className="w40">
            <PlanComponent
              plans={perms?.filter(
                (ele) => ele.price && ele.digital_verification
              )}
              loading={create?.loading}
              onChangePerm={onChangePerm}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProfile;
