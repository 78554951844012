import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePSocre } from "../../actions/auth";

const ProfileScoring = ({ visible, handleCancel, company_id }) => {
  const profil = useSelector(
    (state) => state?.profiles?.getPageProfileByID?.data?.data
  );
  const profil_score = useSelector(
    (state) => state?.profiles?.getScore?.data?.data
  );

  const prof_items = profil?.permissions;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (prof_items?.length > 0 && profil_score?.length > 0) {
      setData(extractedData(prof_items));
    }
  }, [profil, profil_score]);

  const commonList = [
    "Social Verification",
    "Email Social Verification",
    "Phone Social Information",
    "AML Person Screening",
    "Crime check",
    "Reference Check",
    "Signature",
    "Overview",
    "Education Details",
    "Address Details",
  ];

  const app_commonList = ["Face Match", "Liveliness Check"];
  const extractedData = (data) =>
    Array.isArray(data)
      ? data
          // ?.filter((ele) => ele?.title !== "Liveliness Check")
          .flatMap((item) => {
            const permissions = item.sections.flatMap((section) => {
              const p = [];
              const ps = profil_score?.find(
                (it) => it.record_id === section?.id
              );
              p.push({
                record_id: section.id,
                page_profile_id: profil?.id,
                information_score:
                  item?.title === "Liveliness Check"
                    ? 0
                    : ps?.information_score !== null
                    ? ps?.information_score
                    : section.candidate_verification
                    ? section.digital_verification
                      ? 50
                      : 100
                    : 0,
                digital_verification_score:
                  item?.title === "Liveliness Check"
                    ? 100
                    : ps?.digital_verification_score !== null
                    ? ps?.digital_verification_score
                    : section.candidate_verification
                    ? section.digital_verification
                      ? 50
                      : 0
                    : section.digital_verification
                    ? 100
                    : 0,
                candidate_verification: section.candidate_verification,
                digital_verification: section.digital_verification,
                external_verification: section.external_verification,
                recruiter_verification: section.recruiter_verification,
              });
              return p;
            });
            return permissions;
          })
      : [];

  const onChange = (value, permId, key) => {
    if (
      Number.isNaN(Number(value)) ||
      Number(value) > 100 ||
      Number(value) < 0
    ) {
      return;
    }
    let idx = data?.findIndex((ele) => ele.record_id === permId);
    data[idx][key] = value;
    if (key === "digital_verification_score") {
      data[idx]["information_score"] = 100 - value;
    } else {
      data[idx]["digital_verification_score"] = 100 - value;
    }

    setData([...data]);
  };
  console.log({
    data,
  });
  return (
    <Modal
      className="credit-modal profile-scoring right-alinged-modal"
      title={
        <div className="modal-title">
          <span className="modal-head">Profile Scoring</span>
          <span className="modal-subtitle">Add Score on your profile</span>
        </div>
      }
      visible={visible}
      onCancel={handleCancel}
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
      footer={[
        <>
          <div className="pt1">
            <button
              className="primary-btn mr1"
              onClick={() => {
                dispatch(
                  updatePSocre({
                    data,
                    id: profil?.id,
                    company_id,
                  })
                );
              }}
            >
              Update Score
            </button>
            <button className="secondary-btn" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </>,
      ]}
    >
      <div style={{ padding: "20px" }}>
        {Array.isArray(prof_items) &&
          prof_items
            // ?.filter((ele) => ele?.title !== "Liveliness Check")
            ?.map((permission) => (
              <>
                {permission?.sections?.filter(
                  (section) =>
                    section?.candidate_verification ||
                    section?.digital_verification
                )?.length > 0 ||
                permission?.candidate_verification ||
                permission?.digital_verification ? (
                  <div className="header">
                    <div className="head-details">
                      <h3>{permission?.title}</h3>
                      {app_commonList?.includes(permission?.title) ? null : (
                        <h3>Applicant filled</h3>
                      )}
                      {!commonList?.includes(permission?.title) && (
                        <h3>Digital Verification</h3>
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="permissions-wrap">
                  {permission?.sections?.map((section) =>
                    section?.candidate_verification ||
                    section?.digital_verification ? (
                      <div className="permissions" key={section.id}>
                        <ul>
                          <li className="d-flex" key={section.id}>
                            <span className="label">
                              {section.section_title}
                            </span>
                          </li>
                          {app_commonList?.includes(
                            permission?.title
                          ) ? null : (
                            <li>
                              <input
                                value={
                                  data?.find(
                                    (ele) => ele.record_id === section.id
                                  )?.information_score
                                }
                                disabled={
                                  !section?.digital_verification ||
                                  !section?.candidate_verification
                                }
                                onChange={(e) => {
                                  onChange(
                                    e.target.value,
                                    section.id,
                                    "information_score"
                                  );
                                }}
                              />
                            </li>
                          )}
                          {!commonList?.includes(permission?.title) && (
                            <li>
                              <input
                                disabled={
                                  (app_commonList?.includes(
                                    permission?.title
                                  ) &&
                                    data?.find(
                                      (ele) => ele.record_id === section.id
                                    )?.digital_verification_score === 100) ||
                                  !section?.digital_verification ||
                                  !section?.candidate_verification
                                }
                                value={
                                  data?.find(
                                    (ele) => ele.record_id === section.id
                                  )?.digital_verification_score
                                }
                                onChange={(e) => {
                                  onChange(
                                    e.target.value,
                                    section.id,
                                    "digital_verification_score"
                                  );
                                }}
                              />
                            </li>
                          )}
                        </ul>
                      </div>
                    ) : null
                  )}
                </div>
              </>
            ))}
      </div>
    </Modal>
  );
};

export default ProfileScoring;
