import moment from "moment";
import React from "react";
import TableComponent from "../Common/Table/Table";
import { amountFormat } from "../Profiles/Plan";

const CreditLogs = ({ data }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "profile",
      key: "profile",
      render: (text) => text?.title,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <div>Rs. {amountFormat(text)}</div>,
    },
    {
      title: "Used By",
      dataIndex: "user",
      key: "user",
      render: (text) => text?.name,
    },
    {
      title: "Transaction Type",
      dataIndex: "tranx_type",
      align: "center",
      key: "tranx_type",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "Credit / Debit",
      dataIndex: "credits",
      key: "credits",
      align: "center",
      render: (text, record) => (
        <span className={`${record?.tranx_type?.toLowerCase()}`}>{text}</span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("DD-MM-YYYY, HH:mm A"),
    },
  ];

  return (
    <div className="pt1">
      <TableComponent
        rowKey="id"
        columns={[...columns]}
        data={data ?? []}
        hasCheckbox={false}
        className="settingsTable"
      />
    </div>
  );
};

export default CreditLogs;
