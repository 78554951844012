import React, { useCallback, useEffect } from "react";
import Table from "../Common/Table/Table";
import { usersList } from "../../actions/employees/employees";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const ClientUsers = ({ company_id }) => {
  const dispatch = useDispatch();
  const users_list = useSelector((state) => state?.users?.list?.data?.data);
  const totalCount = useSelector(
    (state) => state?.users?.list?.data?.total_count
  );

  const location = useLocation();

  const [filters, setFilters] = React.useState({});

  useEffect(() => {
    dispatch(
      usersList({
        ...filters,
        params: {
          company_id,
        },
      })
    );
  }, []);
  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      render: (text, data, idx) => {
        return <div className={`status-line`}>{idx + 1}</div>;
      },
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      className: "user-col",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
      className: "user-col",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "user-col",
    },
    {
      title: "Mobile",
      dataIndex: "primary_phone",
      key: "primary_phone",
      className: "user-col",
    },
    // {
    //   title: "Client",
    //   dataIndex: "client",
    //   className: "user-col",
    //   key: "client",
    // },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      className: "user-col",
      render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      className: "user-col",
      key: "last_login",
      render: (text) => (
        <div>{text ? moment(text).format("DD MMM YYYY") : "-"}</div>
      ),
    },
    {
      title: "Role",
      dataIndex: "roles",
      className: "user-col",
      key: "roles",
      render: (text) => <div>{text?.join(", ")}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "user-col",
      key: "status",
      render: (text) => (
        <div className={`status ${text === "deleted" ? "inactive" : text}`}>
          {text === "deleted" ? "In-Active" : text}
        </div>
      ),
    },
  ];

  return (
    <div className="pt1">
      <Table
        columns={columns}
        placeholder={"Search by Employee Name, Mobile, Email"}
        data={users_list?.length ? users_list : []}
        pagination={{
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entires`;
          },
          total: totalCount?.total_count || 0,
          activePage: filters.page,
          onChange: useCallback(
            (page) => {
              dispatch(
                usersList({
                  ...filters,
                  params: { page_number: page, company_id },
                })
              );
              setFilters({
                ...filters,
                page,
              });
              // eslint-disable-next-line
            },
            [filters]
          ),
        }}
        loading={users_list?.loading}
      />
    </div>
  );
};

export default ClientUsers;
