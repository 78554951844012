import { DownSquareOutlined, FormOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message, Modal, Switch } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import {
  CompanyEnable,
  enableStaffCompany,
  getCompanyList,
} from "../../actions/clients/clients";
import json2csv from "json2csv";
import { ClientListExport } from "../../actions/employees/employees";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import { hasAccess } from "../../utils";
import Table from "../Common/Table/Table";
import "./clients.css";
import { saveAs } from "file-saver";
import { headerTitles } from "../../actions/auth";
import OnboardPreview from "./OnboardPreview";

const ClientDashboard = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState({});
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState({});
  const [previewOpen, setPreviewOpen] = React.useState({
    open: false,
    record: {},
  });

  const client_list_export = useSelector((state) => state?.client?.export);
  const client_data = useSelector(
    (state) => state?.client?.getClientList?.data?.data ?? []
  );
  const client_loading = useSelector(
    (state) => state?.client?.getClientList?.loading
  );
  const [expLoading, setExpLoading] = React.useState(false);

  const history = useHistory();
  const client_enable = useSelector((state) => state?.client?.enalbeCmp);

  const totalRecords = useSelector(
    (state) => state?.client?.getClientList?.data?.total_count
  );
  const [searchValue, setSearchValue] = useState("");
  const columns = [
    {
      title: "S.NO",
      dataIndex: "series",
      key: "series",
      width: 100,
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Primary Contact Email",
      dataIndex: "primary_contact_email",
      key: "primary_contact_email",
    },
    {
      title: "KYC Status",
      dataIndex: "kyc_status",
      key: "kyc_status",
      render: (text) => (
        <div
          className={`status status_${
            text === "kyc_auto_approved" ? "auto" : text ? text : ""
          } `}
        >
          {text ? text?.replace(/_/g, " ")?.toUpperCase() : "AUTO APPROVED"}
        </div>
      ),
    },
    {
      title: "Mobile Number",
      dataIndex: "primary_phone",
      key: "primary_phone",
    },
    {
      title: "Contact person",
      dataIndex: "primary_contact_name",
      key: "primary_contact_name",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <div>{text ? moment(text).format("DD MMM YYYY") : ""}</div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      fixed: "right",
      width: 160,
      key: "id",
      render: (text, record) => (
        <div
          className="actionsCard"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            checked={record.enabled}
            onChange={(val) => onChange(val, text)}
            disabled={
              !hasAccess("clients", "write") || record?.kyc_status == "kyc_init"
            }
          />
          <button
            className="editButtonDiv"
            style={{ color: "#454545" }}
            onClick={() => {
              history.push({
                pathname: "/admin/clients/add-new-client/organisation-profile",
                state: {
                  id: text,
                  country: record?.country,
                },
              });
            }}
            disabled={
              !hasAccess("clients", "write") ||
              !record.enabled ||
              record?.kyc_status == "kyc_init"
            }
          >
            <FormOutlined />
          </button>
          <Dropdown
            disabled={!hasAccess("clients", "write") || !record.enabled}
            overlay={menu(record)}
            placement="bottomRight"
            arrow
          >
            <div
              className="expandCardIconDiv"
              style={{ opacity: record.enabled ? 1 : 0.5 }}
            >
              <DownSquareOutlined />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const menu = (record) => (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() => {
          endpoint
            .post(apis.resetPassword, {
              email: record.primary_email,
            })
            .then((res) => {
              message.success(
                "Reset password link has been sent to your email."
              );
            })
            .catch((err) => {
              message.error("Failed to send email.");
            });
        }}
        style={{ padding: 10 }}
      >
        Reset Password
      </Menu.Item>
      {/* <Menu.Item
        key={2}
        onClick={() => {
          setDeleteConfirm(true);
          setSelectedRecord(record);
        }}
        style={{ padding: 10 }}
      >
        Delete
      </Menu.Item> */}
    </Menu>
  );

  React.useEffect(() => {
    if (client_list_export?.success) {
      window.open(client_list_export?.data?.response["CSV File URL"], "_blank");
      dispatch({
        type: ClientListExport.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_list_export]);

  React.useEffect(() => {
    dispatch(
      headerTitles({
        title: "Clients",
        description: "",
      })
    );
    dispatch(
      getCompanyList({
        page: 1,
        params: { page_number: filters.page_number },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (client_enable?.success) {
      dispatch(
        getCompanyList({
          page: 1,
          search_key: searchValue,
          params: { page_number: filters.page_number },
        })
      );
      dispatch({
        type: CompanyEnable.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_enable]);

  const handleSearch = (value) => {
    setSearchValue(value);
    dispatch(getCompanyList({ search_key: value }));
    setFilters({
      ...filters,
      search_key: value,
    });
  };

  const onChange = (checked, id) => {
    dispatch(
      enableStaffCompany({
        cmpId: id,
        enabled: checked,
      })
    );
  };

  return (
    <>
      <Modal
        visible={deleteConfirm}
        title="Preview"
        okText="Confirm"
        onCancel={() => {
          setDeleteConfirm(false);
        }}
        onOk={() => {
          endpoint
            .post(apis.deleteClient, {
              company_id: selectedRecord.id,
            })
            .then(() => {
              message.success("Deleted Successfully.");
              dispatch(
                getCompanyList({
                  page: 1,
                })
              );
              setDeleteConfirm(false);
            })
            .catch((err) => {
              message.error("Failed to delete.");
            });
        }}
      >
        Performing this action will delete your client.
      </Modal>

      <Table
        columns={columns}
        scroll={{ x: 1800 }}
        placeholder={"Search by Company Name, Mobile, Email"}
        data={client_data ? client_data : []}
        isTopFilter={true}
        handleSearch={handleSearch}
        headerButtonProps={{
          buttonName: "+ Add New Client",
          route: "/admin/clients/add-new-client/organisation-profile",
        }}
        isHeaderButton={hasAccess("clients", "write")}
        pagination={{
          total: totalRecords || 0,
          onChange: (pagee) =>
            dispatch(
              getCompanyList(
                { params: { page_number: pagee }, ...filters },
                setFilters({ page_number: pagee })
              )
            ),
        }}
        exportLoading={expLoading}
        topFilters={[]}
        rowClassName={(record) => {
          if (record.enabled) return "";
          return "row-light";
        }}
        loading={client_loading}
        handleRowClick={(_e, record) => {
          history.push("/admin/client/details", { record });
        }}
        exportToExcel={() => {
          setExpLoading(true);
          endpoint
            .post(apis.clientList + `?isCsv=true`, {
              // params: {
              //   isCsv: true
              // }
            })
            .then((res) => {
              setExpLoading(false);
              let cli_data = res?.data?.data;
              let csv = json2csv.parse(
                cli_data?.map((ele) => {
                  let obj = {};
                  obj["Name"] = ele.name;
                  obj["Email"] = ele.email;
                  obj["Registration No"] = ele.registration_no;
                  obj["Address"] = ele.address;
                  obj["City"] = ele.city;
                  obj["State"] = ele.state;
                  obj["Country"] = ele.country;
                  obj["Pincode"] = ele.pincode;
                  obj["Office Phone"] = ele.office_no;
                  obj["GST No"] = ele.gst_no;
                  obj["Tan No"] = ele.tan_no;
                  obj["Primary Contact Name"] = ele.primary_contact_name;
                  obj["Primary Contact Phone"] = ele.primary_phone;
                  obj["Primary Contact Email"] = ele.primary_contact_email;
                  obj["Custom Plan"] = ele.custom_price_plan ? "Yes" : "No";
                  obj["Created Date"] = moment(ele.created_at).format(
                    "DD-MM-YYYY, HH:mm A"
                  );
                  obj["Updated Date"] = moment(ele.updated_at).format(
                    "DD-MM-YYYY, HH:mm A"
                  );
                  return obj;
                })
              );
              var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
              saveAs(blob, "clients.csv");
            });
        }}
      />
    </>
  );
};

export default withRouter(ClientDashboard);
