import { CloseCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import "../clients.css";
import ContactDetails from "./ContactDetails";
// import KycDocuments from "./KycDocuments";
import AddNewClientOrganisationProfile from "./OrganisationProfile";
import ProductConfiguration from "./ProductConfiguration";
import RegionalSettings from "./RegionalSettings";
import { useLocation } from "react-router-dom";
import { headerTitles } from "../../../actions/auth";
import { useDispatch } from "react-redux";

const AddNewClientDashboard = (props) => {
  const history = props.history;
  const dispatch = useDispatch()
  const location = useLocation()

  const pageTitle = location.state?.id ? "Edit Client" : "Add New Client";
  useEffect(() => {
    dispatch(
      headerTitles({
        title: pageTitle,
        description: ""
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card">
      <div style={{ height: '100%', position: "relative" }}>
        <CloseCircleOutlined
          style={{ fontSize: 20, position: "absolute", right: -10, top: 10 }}
          onClick={() => history.push("/admin/clients/")}
        />
      </div>
      <div
        className="d-flex flex-start"
        style={{ alignItems: "flex-start", height: "95%" }}
      >
        <Route
          exact
          path={props.path + "/organisation-profile"}
          component={AddNewClientOrganisationProfile}
        />
        <Route
          exact
          path={props.path + "/contact-details"}
          component={ContactDetails}
        />
        <Route
          exact
          path={props.path + "/regional-settings"}
          component={RegionalSettings}
        />
        <Route
          exact
          path={props.path + "/product-configuration"}
          component={ProductConfiguration}
        />
      </div>
    </div>
  );
};

export default withRouter(AddNewClientDashboard);
