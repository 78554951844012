import React from "react";

import cross from "../../assets/icons/cross.svg";

export const amountFormat = (amount, decimal_scale = 2) => {
  const c = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: decimal_scale,
    minimumFractionDigits: decimal_scale,
  });

  return amount === null || Number.isNaN(Number(amount))
    ? "0.00"
    : c.format(parseFloat(amount));
};

const PlanComponent = ({ plans, loading, onChangePerm }) => {
  const total = plans.reduce((acc, plan) => acc + plan.price, 0);

  return (
    <div className="package-plan w40">
      <h2>Plan</h2>
      <div className="plans">
        {plans.map((plan, index) => (
          <div className="plan-item" key={index}>
            <span className="name">{plan.name}</span>
            <span
              className="price flex-center"
              onClick={() => {
                onChangePerm(plan?.permission_id);
              }}
            >
              &#8377;{plan.price}
              {plan?.name === "Aadhaar Details" ||
              plan?.name === "Face Match" ||
              plan?.name === "PAN Details" ? null : (
                <img src={cross} alt="" width={10} style={{ marginLeft: 5 }} />
              )}
            </span>
          </div>
        ))}
      </div>
      <div className="bottom">
        <div className="plan-item total-p">
          <span className="name">Total</span>
          <span className="price">&#8377;{amountFormat(total)}</span>
        </div>
        <div className="plan-item">
          <span className="desc">
            By proceeding, you agree to our Privacy Policy
          </span>
        </div>
        <button
          className="primary-btn w100"
          style={{ marginTop: 10 }}
          disabled={loading || total <= 0}
        >
          Create Plan
        </button>
      </div>
    </div>
  );
};

export default PlanComponent;
