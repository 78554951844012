import { Modal } from "antd";
import React from "react";
import AddCredits from "../Clients/AddCredits";

const AddCreditsModal = ({
  visible,
  handleCancel,
  company_id,
  onSuccess,
  viewInv,
  record,
}) => {
  return (
    <div>
      <Modal
        className="credit-modal right-alinged-modal"
        title={
          !viewInv ? (
            <div className="modal-title">
              <span className="modal-head">{"Add Credits"}</span>
              <span className="modal-subtitle">
                Add the number of credits you want to purchase against each plan
              </span>
            </div>
          ) : (
            <div className="modal-title">
              <span className="modal-head">{"View Invoice"}</span>
              <span className="modal-subtitle">
                Please find the details of the purchased items below.
              </span>
            </div>
          )
        }
        visible={visible}
        onCancel={handleCancel}
        width={900}
        footer={null}
      >
        <AddCredits
          company_id={company_id}
          handleCancel={handleCancel}
          onSuccess={onSuccess}
          viewInv={viewInv}
          record={record}
        />
      </Modal>
    </div>
  );
};

export default AddCreditsModal;
