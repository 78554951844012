import React from "react";
// import Input from "../FormComponents/InputComponent";
import { Button, Checkbox, Form, Input, message, Typography } from "antd";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter, Link } from "react-router-dom";
import * as yup from "yup";
import { RouteConstants } from "../../utils";
import { Login, login } from "./../../actions/auth";
// import { RouteConstants } from "../../utils";
const { Text, Title } = Typography;

const ValidationSchema = yup.object().shape({
  email: yup.string().email().required("Please provide valid email"),
  password: yup.string().required("Password is required"),
});

const LoginForm = (props) => {
  const [accountType] = React.useState("simulator");
  const dispatch = useDispatch();
  const loginResponse = useSelector((state) => state.auth.login);
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });

  React.useEffect(() => {
    if (loginResponse.success) {
      if (loginResponse?.data?.data && loginResponse?.data?.data?.auth_token) {
        localStorage.setItem("token", loginResponse?.data?.data?.auth_token);
        localStorage.setItem("email", formik.values.email);
        localStorage.setItem(
          "permissions",
          JSON.stringify(loginResponse?.data?.data?.permission)
        );
        dispatch({
          type: Login.RESET,
        });
        message.success("Logged In Successfully");
        history.push(RouteConstants.DASHBOARD);
      }
    } else if (loginResponse?.success === false) {
      message.error(
        <span className="messageText">
          {loginResponse?.data?.message ??
            "Server is down, Please try after sometime."}
        </span>
      );
      dispatch({
        type: Login.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse]);
  return (
    <div className="login-form">
      <div>
        <div className="head-sec text-center">
          <Title level={3}>Welcome Back</Title>
          <Text>Sign in to ZKY Super Admin Portal.</Text>
        </div>
      </div>
      <Form
        onFinish={formik.handleSubmit}
        layout="vertical"
        className="loginFormDiv"
      >
        <Form.Item
          label={
            accountType === "simulator"
              ? "Email Address"
              : "Client Series Or PCUID"
          }
          name="email"
        >
          <Input
            placeholder="Enter Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error">{formik.errors.email}</div>
          )}
        </Form.Item>

        <Form.Item label="Password">
          <Input.Password
            name="password"
            placeholder="Enter password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="error">{formik.errors.password}</div>
          )}
        </Form.Item>
        <div className="flex-between">
          <label className="d-flex align-center">
            <Checkbox />
            <span className="mlHalf">Remember me</span>
          </label>
          <Link to={"/admin/resetpassword"}>
            <span className="link ml2 cursor">Forgot Password ?</span>
          </Link>
        </div>

        <Form.Item className="loginButtonsDiv">
          <Button
            htmlType="submit"
            className="loginButton w100"
            disabled={loginResponse.loading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withRouter(LoginForm);
