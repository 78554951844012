import React from "react";
import tick from "../../assets/icons/tick.svg";
import cross from "../../assets/icons/cross.svg";
import { useSelector } from "react-redux";
import { amountFormat } from "../../utils";
const VIewProfile = () => {
  const profil = useSelector(
    (state) => state?.profiles?.getPageProfileByID?.data?.data
  );

  const prof_items = profil?.permissions;

  return (
    <div className="modal-content">
      <div className="card-header">
        <span className="plan-name">{profil?.title}</span>
      </div>
      <div className="card-price">
        Rs. {amountFormat(profil?.plan_cost)}{" "}
        <span className="per-credit">/ Credit</span>
      </div>
      <div className="card-description">{profil?.description}</div>

      {Array.isArray(prof_items) &&
        prof_items?.map((permission) => (
          <>
            {permission?.sections?.filter(
              (section) =>
                section?.candidate_verification || section?.digital_verification
            )?.length > 0 ||
            permission?.candidate_verification ||
            permission?.digital_verification ? (
              <div className="header">
                <div className="head-details">
                  <h3>{permission?.title}</h3>
                  <h3>Show to Applicant</h3>
                  <h3>Digital Verification</h3>
                </div>
              </div>
            ) : null}
            <div className="permissions-wrap">
              {permission?.sections?.map((section) =>
                section?.candidate_verification ||
                section?.digital_verification ? (
                  <div className="permissions" key={section.id}>
                    <ul>
                      <li className="d-flex" key={section.id}>
                        <span className="label">{section.section_title}</span>
                      </li>
                      <li>
                        <span style={{ marginLeft: 50 }}>
                          {section?.candidate_verification ? (
                            <img src={tick} alt="" width={15} />
                          ) : (
                            <img src={cross} alt="" width={12} />
                          )}
                        </span>{" "}
                      </li>
                      <li>
                        <span style={{ marginLeft: 50, color: "#333" }}>
                          {section?.digital_verification ? (
                            <img src={tick} alt="" width={15} />
                          ) : permission.title !== "Overview" &&
                            permission.title !== "Address Details" &&
                            permission.title !== "Reference Check" &&
                            permission.title !== "Signature" &&
                            // section.section_title !== "Passport Details" &&
                            permission.title !== "Education Details" ? (
                            <img src={cross} alt="" width={12} />
                          ) : (
                            "-"
                          )}
                        </span>{" "}
                      </li>
                    </ul>
                  </div>
                ) : null
              )}
            </div>
          </>
        ))}
      {Array.isArray(prof_items) &&
        prof_items
          ?.filter(
            (ele) =>
              ele.title === "Address Details" ||
              ele.title === "Education Details"
          )
          ?.map((permission, i) => (
            <>
              {i === 0 && (
                <div className="header">
                  <div className="head-details">
                    <h3>Third Party Verifications</h3>
                    <h3 style={{ marginLeft: 50 }}>Send to Third Party</h3>
                  </div>
                </div>
              )}

              {permission?.sections?.map((section) => (
                <div className="permissions" key={section.id}>
                  <ul>
                    <li className="d-flex" key={section.id}>
                      <span className="label">{section.section_title}</span>
                    </li>
                    <li>
                      <span style={{ marginLeft: 100 }}>
                        {section?.external_verification ? (
                          <img src={tick} alt="" width={15} />
                        ) : (
                          <img src={cross} alt="" width={12} />
                        )}
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              ))}
            </>
          ))}
    </div>
  );
};

export default VIewProfile;
