import { Button, Checkbox, Input, message } from "antd";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddStaffUser,
  addStaffUser,
  staffUserList,
} from "../../../../actions/employees";
import { apis } from "../../../../config/APIs";
import endpoint from "../../../../config/Axios";
import * as yup from "yup";
import PasswordCheck from "../../../Common/PasswordCheck";
const AddStaff = ({ onCancel, selectedData, pageNumbers }) => {
  const dispatch = useDispatch();
  const add_staff = useSelector((state) => state?.staff_users?.add?.data);
  const add_staff_loading = useSelector(
    (state) => state?.staff_users?.add?.loading
  );
  const formik = useFormik({
    initialValues: {
      email: "",
      role: "admin",
      password: "",
      name: "",
      phone_no: "",
      designation: "",
      permission_ids: [],
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Email must be valid.")
        .required("Email is required"),
      password: yup.string().when("id", {
        is: selectedData?.id,
        then: yup.string(),
        otherwise: yup.string().required("Password is required"),
      }),
      phone_no: yup
        .string()
        .required("Enter phone Number")
        .min(10, "Enter Valid Phone No")
        .max(10, "Enter Valid Phone No"),
      name: yup.string().required("Name is required"),
      designation: yup.string().required("Designation is required"),
      permission_ids: yup
        .array()
        .of(yup.string().required("at least 1 Permission required"))
        .min(1, "at least 1 Permission required"),
    }),
    onSubmit: (vals) => {
      dispatch(addStaffUser(vals));
    },
  });

  console.log(selectedData, "staffdetails");
  const [permissions, setPermission] = useState({});
  const [passwordCheck, setPasswordCheck] = useState(false);

  useEffect(() => {
    endpoint.post(apis.staffPermissions).then((res) => {
      let keys = res.data?.data || [];
      let permissionObj = {};
      keys?.forEach((ele) => {
        let key = ele.key?.replace("staff_permission_", "");
        if (key?.includes("card_imports")) {
          key = ["card_imports", key.replace("card_imports_", "")];
        } else if (key?.includes("staff_users")) {
          key = ["staff_users", key.replace("staff_users_", "")];
        } else if (key?.includes("cards_vendors")) {
          key = ["card_vendors", key.replace("cards_vendors_", "")];
        } else {
          key = key.split("_");
        }
        if (permissionObj[key[0]]) {
          permissionObj[key[0]] = {
            ...permissionObj[key[0]],
            [key[1]]: ele.id,
          };
        } else {
          permissionObj[key[0]] = {
            [key[1]]: ele.id,
          };
        }
      });
      setPermission(permissionObj);
    });
    return () => {
      formik.resetForm();
      formik.setFieldValue("permission_ids", []);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (add_staff?.error === false && selectedData?.id) {
      message.success("User Updated Successfully");
      dispatch({
        type: AddStaffUser.RESET,
      });
      dispatch(
        staffUserList({ params: { page_number: pageNumbers.page_number } })
      );
      onCancel();
      formik.resetForm();
      formik.setFieldValue("permission_ids", []);
    }
    if (add_staff?.error === false && selectedData?.id === undefined) {
      // {selectedData?.id ? message.success("Added User"):message.success("Added User")}
      message.success("User Created Successfully");
      // message.success("Added user");
      dispatch({
        type: AddStaffUser.RESET,
      });
      dispatch(staffUserList());
      onCancel();
      formik.resetForm();
      formik.setFieldValue("permission_ids", []);
    } else if (add_staff?.error === true) {
      message.error(add_staff.message);
    }

    // eslint-disable-next-line
  }, [add_staff]);

  useEffect(() => {
    if (selectedData?.id === undefined) {
      dispatch({
        type: AddStaffUser.RESET,
      });
      formik.resetForm();
    }
  }, [selectedData?.id]);

  useEffect(() => {
    if (selectedData?.id && Array.isArray(selectedData?.permission_data)) {
      // if(selectedData?. id){
      let permission_ids =
        selectedData?.permission_data?.map((ele) => ele.id) || [];
      delete selectedData?.permission_data;
      formik.setValues({
        ...selectedData,
        permission_ids,
      });
    }
    // else{
    //   formik.setValues({
    //     ...selectedData,
    //   })
    // }
    if (selectedData?.id) {
      setPasswordCheck(false);
    }
  }, [selectedData]);

  return (
    <>
      <form className="modal-form staff" onSubmit={formik.handleSubmit}>
        <div className="input-div">
          <label>
            Name<span className="error">*</span>
          </label>
          <Input
            autoComplete="off"
            placeholder="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
          />
          {formik.touched.name && formik.errors.name && (
            <div className="error">{formik.errors.name}</div>
          )}
        </div>
        <div className="input-div">
          <label className="required">
            Email<span className="error">*</span>
          </label>
          <Input
            disabled={selectedData?.id}
            autoComplete="new-password"
            placeholder="Enter Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error">{formik.errors.email}</div>
          )}
        </div>
        <div className="input-div">
          <label>
            Phone No<span className="error">*</span>
          </label>
          <Input
            maxLength={15}
            autoComplete="off"
            placeholder="Enter Phone No"
            value={formik.values.phone_no}
            onChange={(e) => {
              if (Number.isNaN(Number(e.target.value)) && e.target.value) {
                return;
              }
              formik.setFieldValue("phone_no", e.target.value);
            }}
            name="phone_no"
          />
          {formik.touched.phone_no && formik.errors.phone_no && (
            <div className="error">{formik.errors.phone_no}</div>
          )}
        </div>
        <div className="input-div">
          <label>
            Designation<span className="error">*</span>
          </label>
          <Input
            autoComplete="off"
            placeholder="Enter Designation"
            value={formik.values.designation}
            onChange={formik.handleChange}
            name="designation"
          />
          {formik.touched.designation && formik.errors.designation && (
            <div className="error">{formik.errors.designation}</div>
          )}
        </div>
        {!selectedData?.id && (
          <div className="input-div">
            <label>
              Password<span className="error">*</span>
            </label>
            <Input.Password
              autoComplete="new-password"
              placeholder="Enter Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              name="password"
            />
            {formik.touched.password && formik.errors.password && (
              <div className="error">{formik.errors.password}</div>
            )}
            <PasswordCheck
              dark={true}
              password={formik.values.password}
              setPasswordCheck={setPasswordCheck}
              id={selectedData?.id}
            />
          </div>
        )}
        <div className="input-div">
          <div className="flex-between mb2">
            <label>Permissions</label>
            <div className="floatRightDiv">
              <p className="smallP all">
                Read All
                <Checkbox
                  checked={Object.keys(permissions).reduce(
                    (accumulator, key) => {
                      const permission = permissions[key];
                      return (
                        accumulator &&
                        formik?.values.permission_ids?.includes(permission.read)
                      );
                    },
                    true
                  )}
                  onChange={(e) => {
                    let arr = formik.values.permission_ids;
                    let readId = [];
                    if (e.target.checked) {
                      Object.keys(permissions)?.map((key) => {
                        if (!arr?.includes(permissions[key].read)) {
                          arr.push(permissions[key].read);
                        }
                      });
                    } else {
                      Object.keys(permissions)?.map((key) => {
                        readId.push(permissions[key].read);
                      });
                      arr = arr?.filter((ele) => !readId?.includes(ele));
                    }
                    formik.setFieldValue("permission_ids", arr);
                  }}
                  disabled={
                    Object.keys(permissions)?.length * 2 ===
                    formik.values.permission_ids?.length
                  }
                />
              </p>
              <p className="smallP">
                Write All
                <Checkbox
                  checked={
                    Object.keys(permissions)?.length * 2 ===
                    formik.values.permission_ids?.length
                  }
                  onChange={(e) => {
                    console.log("add staff check box ", e);
                    let arr = formik.values.permission_ids;
                    if (e.target.checked) {
                      Object.keys(permissions)?.map((key) => {
                        if (!arr?.includes(permissions[key].write)) {
                          arr.push(permissions[key].write);
                        }
                        if (!arr?.includes(permissions[key].read)) {
                          arr.push(permissions[key].read);
                        }
                      });
                    } else {
                      arr = [];
                    }
                    formik.setFieldValue("permission_ids", arr);
                  }}
                />
              </p>
            </div>
          </div>
          {Object.keys(permissions).map((key) => {
            return (
              <div className="flex-between mb1">
                <b>{_.startCase(key)}</b>
                <div className="floatRightDiv">
                  <p className="smallP mlHalf">
                    Read
                    <Checkbox
                      checked={formik.values.permission_ids?.includes(
                        permissions[key].read
                      )}
                      onChange={(e) => {
                        let arr = formik.values.permission_ids;
                        let idx = arr?.indexOf(permissions[key].read);
                        if (idx > -1) {
                          arr.splice(idx, 1);
                        } else {
                          if (!arr?.includes(permissions[key].read)) {
                            arr.push(permissions[key].read);
                          }
                        }
                        formik.setFieldValue("permission_ids", arr);
                      }}
                      disabled={formik.values.permission_ids?.includes(
                        permissions[key].write
                      )}
                    />
                  </p>

                  <p className="smallP">
                    Write
                    <Checkbox
                      checked={formik.values.permission_ids?.includes(
                        permissions[key].write
                      )}
                      onChange={(e) => {
                        let arr = formik.values.permission_ids;
                        let idx = arr?.indexOf(permissions[key].write);

                        if (idx > -1) {
                          arr.splice(idx, 1);

                          let idxRead = arr?.indexOf(permissions[key].read);
                          arr.splice(idxRead, 1);
                        } else {
                          if (!arr?.includes(permissions[key].write)) {
                            arr.push(permissions[key].write);
                          }
                          if (!arr?.includes(permissions[key].read)) {
                            arr.push(permissions[key].read);
                          }
                        }
                        formik.setFieldValue("permission_ids", arr);
                      }}
                      disabled={key === "leads"}
                    />
                  </p>
                </div>
              </div>
            );
          })}
          {formik.touched.permission_ids && formik.errors.permission_ids && (
            <div className="error">{formik.errors.permission_ids}</div>
          )}
        </div>
        {add_staff?.errors && <div className="error">{add_staff?.errors}</div>}
        <div className="d-flex">
          <Button
            key="1"
            htmlType="submit"
            className="formButton mt1"
            disabled={passwordCheck || add_staff_loading}
            loading={add_staff_loading}
          >
            {selectedData?.id ? "Update" : "Save"}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            onClick={() => {
              onCancel();
              formik.resetForm();
              formik.setFieldValue("permission_ids", []);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddStaff;
