import React from "react";
import { useSelector } from "react-redux";
import Invoices from "../Invoices/Invoices";
import Profiles from "../Profiles/Profiles";
import CreditLogs from "../Invoices/CreditLogs";

const Credits = ({ getAllInvoices, invoices }) => {
  const [tab, setTab] = React.useState("a");
  const compRes = useSelector(
    (state) => state?.client?.getStaffCmp?.data?.data
  );
  const items = [
    {
      key: "a",
      label: "Plans",
    },
    {
      key: "b",
      label: "Invoices",
    },
    {
      key: "ce",
      label: "Usage Logs",
    },
  ];
  return (
    <div className="loan-details">
      <div className="pt2 loan-sec">
        <div className="applicant-details">
          <div className="promoter-details">
            <h3>Plan Summary</h3>
            <div className="flex-between">
              <div className="details">
                <div className="item-detail">
                  <label>Available Credits</label>
                  <p>{compRes?.credits_remaining ?? 0}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="tabs-sec">
          <ul>
            {items.map(
              (ele) =>
                ele?.label && (
                  <li
                    onClick={() => setTab(ele.key?.toLowerCase())}
                    className={`${
                      tab.toLowerCase() === ele.key?.toLowerCase()
                        ? "active"
                        : ""
                    }`}
                  >
                    {ele.label}
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
      {tab === "b" && (
        <Invoices
          company_id={compRes?.id}
          getAllInvoices={getAllInvoices}
          data={invoices}
        />
      )}
      {tab === "a" && <Profiles company_id={compRes?.id} />}
      {tab === "ce" && (
        <CreditLogs company_id={compRes?.id} data={compRes?.profile_logs} />
      )}
    </div>
  );
};

export default Credits;
