import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import {
  CREATE_PAGE_PROFILE,
  DELETE_PAGE_PROFILE,
  GET_ALL_PAGE_PROFILES,
  GET_PAGE_PROFILE_BY_ID,
  GetProfileScore,
  Login,
  Logout,
  UPDATE_PAGE_PROFILE,
  UpdateProfileScore,
} from "./auth";

function login(req) {
  return sagaFunctions(Login, "post", apis.staffLogin, req.payload, {
    "Content-Type": "application/json; charset=utf-8",
  })();
}

function logout() {
  return sagaFunctions(Logout, "post", apis.staffLogout, null, {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: localStorage.getItem("token"),
  })();
}

function createPageProfileSaga(req) {
  return sagaFunctions(
    CREATE_PAGE_PROFILE,
    "post",
    apis.createPageProfile,
    req.payload
  )();
}

function updatePageProfileSaga(req) {
  return sagaFunctions(
    UPDATE_PAGE_PROFILE,
    "post",
    apis.updatePageProfile,
    req.payload
  )();
}

function getAllPageProfilesSaga(req) {
  return sagaFunctions(
    GET_ALL_PAGE_PROFILES,
    "post",
    apis.getAllPageProfiles,
    req.payload
  )();
}

function getPageProfileByIDSaga(req) {
  console.log({ req });
  return sagaFunctions(
    GET_PAGE_PROFILE_BY_ID,
    "get",
    apis.getPageProfileByID,
    req.payload
  )();
}

function deletePageProfileSaga(req) {
  return sagaFunctions(
    DELETE_PAGE_PROFILE,
    "post",
    apis.deletePageProfile,
    req.payload
  )();
}

function updateProfileSocre(req) {
  return sagaFunctions(
    UpdateProfileScore,
    "post",
    apis.updateScoresBasedOnProfile + `?page_profile_id=${req.payload?.id}&company_id=${req.payload?.company_id}`,
    req.payload?.data
  )();
}

function getProfileSocre(req) {
  return sagaFunctions(
    GetProfileScore,
    "get",
    apis.getScoresBasedOnProfile,
    req.payload
  )();
}

export function* loginWatcher() {
  yield takeLatest(Login.REQUEST, login);
  yield takeLatest(Logout.REQUEST, logout);
  yield takeLatest(CREATE_PAGE_PROFILE.REQUEST, createPageProfileSaga);
  yield takeLatest(UPDATE_PAGE_PROFILE.REQUEST, updatePageProfileSaga);
  yield takeLatest(GET_ALL_PAGE_PROFILES.REQUEST, getAllPageProfilesSaga);
  yield takeLatest(GET_PAGE_PROFILE_BY_ID.REQUEST, getPageProfileByIDSaga);
  yield takeLatest(DELETE_PAGE_PROFILE.REQUEST, deletePageProfileSaga);
  yield takeLatest(UpdateProfileScore.REQUEST, updateProfileSocre);
  yield takeLatest(GetProfileScore.REQUEST, getProfileSocre);
}
