import React, { useEffect, useState } from "react";
import { Table, Input, Form, message } from "antd";
import usePayAxios from "../../config/usePayApis";
import { apis } from "../../config/APIs";
import { amountFormat } from "../Profiles/Plan";
import { getAllPageProfiles } from "../../actions/auth";
import { useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";

const AddCredits = ({
  company_id,
  handleCancel,
  onSuccess,
  viewInv,
  record,
}) => {
  const dispatch = useDispatch();
  const { onCall: getAllPlans, data } = usePayAxios({
    api: apis.getAllPageProfiles,
    method: "post",
  });
  const [form] = Form.useForm();
  const [discountType] = useState("%");
  const [totalAmount, setTotalAmount] = useState(0); // initial total amount
  const [discountAmount, setDiscountAmount] = useState(0); // initial total amount
  const [taxAmount, setTaxAmount] = useState(0); // initial total amount
  const [totalCredits, setTotalCredits] = useState(0); // initial total amount
  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getAllPlans({
      params: {
        pagination: false,
        company_id,
      },
    });
  }, []);

  useEffect(() => {
    if (record?.id) {
      setTotalAmount(record?.total_amount);
      setDiscountAmount(record?.discount_amount);
      setDiscount(record?.items?.[0]?.discount);
      setTaxAmount(record?.total_tax_amount);
      setTax(record?.items?.[0]?.tax);
      setTotalCredits(_.sumBy(record?.items, "quantity"));
    }
  }, [record]);

  useEffect(() => {
    if (data?.data?.length && !viewInv) {
      setDataSource(data?.data);
    } else {
      setDataSource(record?.items);
    }
  }, [data?.data]);

  const columns = [
    {
      title: "Plan",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Unit Price",
      dataIndex: "amount",
      key: "amount",
      render: (text) => `₹ ${text}`,
    },
    {
      title: "Enter Qty",
      dataIndex: "quantity",
      key: "quantity",
      render: (_text, record) => (
        <Input
          value={record?.quantity}
          onChange={(value) => handleQuantityChange(value, record.id)}
        />
      ),
    },
  ];

  const view_columns = [
    {
      title: "Plan",
      dataIndex: "item_name",
      key: "item_name",
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (text) => `₹ ${text}`,
    },
    {
      title: "Enter Qty",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Discount (%)",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (_text, rec) =>
        `₹ ${amountFormat(
          Number(viewInv ? rec?.unit_price : rec?.amount) *
            Number(rec?.quantity)
        )}`,
    },
  ];

  const { onCall } = usePayAxios({
    api: apis.addCredits,
    method: "post",
  });

  const handleOk = () => {
    onCall({
      data: {
        company_id: company_id,
        data: dataSource
          ?.filter((ele) => ele.quantity)
          ?.map((ele) => ({
            page_profile_id: ele.id,
            profile_name: ele.title,
            amount: Number(ele.amount) * Number(ele.quantity),
            total_amount:
              Number(ele.amount) * Number(ele.quantity) -
              calculateDis(Number(ele.amount) * Number(ele.quantity)),
            discount_amount: calculateDis(
              Number(ele.amount) * Number(ele.quantity)
            ),
            tax_amount: calculateTax(
              Number(ele.amount) * Number(ele.quantity) -
                calculateDis(Number(ele.amount) * Number(ele.quantity))
            ),
            discount: Number(discount),
            tax: Number(tax),
            credits: Number(ele.quantity),
          })),
      },
    })
      .then((res) => {
        if (res.error === false) {
          message.success(<span className="messageText">{res?.message}</span>);
          dispatch(
            getAllPageProfiles({
              company_id: company_id,
            })
          );
          onSuccess();
          handleCancel();
        }
      })
      .catch((err) => {
        message.error(
          <span className="messageText">{err?.response?.data?.message}</span>
        );
        // setVisible(false);
      });
  };

  const handleQuantityChange = (e, key) => {
    if (Number.isNaN(Number(e.target.value)) || e.target.value?.includes("."))
      return;
    const newDataSource = dataSource.map((item) => {
      if (item.id === key) {
        return { ...item, quantity: e.target.value };
      }
      return item;
    });
    setDataSource(newDataSource);
    calculateTotal(newDataSource, discount, tax);
  };

  const handleDiscountChange = (e) => {
    if (Number.isNaN(Number(e.target.value))) return;
    setDiscount(e.target.value);
    calculateTotal(dataSource, e.target.value, tax);
  };

  const handleTaxChange = (e) => {
    if (Number.isNaN(Number(e.target.value))) return;
    setTax(e.target.value);
    calculateTotal(dataSource, discount, e.target.value);
  };

  const calculateDis = (amt) => {
    if (discountType === "%") {
      return (amt * discount) / 100;
    } else {
      return amt;
    }
  };
  const calculateTax = (amt) => {
    return (amt * tax) / 100;
  };

  const calculateTotal = (dataSource, discount, tax) => {
    let total = 0,
      tc = 0;
    dataSource.forEach((item) => {
      total += Number(item.amount) * Number(item.quantity ? item.quantity : 0);
      tc += parseInt(item.quantity ? item.quantity : 0);
    });
    if (discountType === "%") {
      const disc = discount ? (total * discount) / 100 : 0;
      setDiscountAmount(disc);
      total -= disc;
      const tx = tax ? (total * tax) / 100 : 0;
      setTaxAmount(tx);
      total += tx;
    } else {
      setDiscountAmount(discount);
      total -= discount;
    }
    setTotalCredits(tc);
    setTotalAmount(total);
  };

  const subTotal = Array.isArray(dataSource)
    ? dataSource
        ?.filter((plan) => plan.quantity && parseInt(plan.quantity) > 0)
        ?.reduce(
          (sum, plan) =>
            sum +
            Number(viewInv ? plan?.unit_price : plan.amount) *
              parseInt(plan.quantity),
          0
        )
    : 0;

  return (
    <div>
      {/* <div
        className="modal-content"
        style={{ maxHeight: "52vh", overflowY: "auto" }}
      > */}
      <Table
        dataSource={dataSource ?? []}
        columns={viewInv ? view_columns : columns}
        pagination={false}
        sticky
      />
      {/* </div> */}
      <Form
        form={form}
        layout="vertical"
        style={{
          marginTop: 6,
          position: "absolute",
          width: "92%",
          bottom: "1rem",
        }}
      >
        <div className="flex-end">
          <div className="back-card">
            {!viewInv ? (
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <Form.Item label="Discount">
                  <Input.Group compact>
                    <Input
                      value={discount}
                      onChange={handleDiscountChange}
                      style={{ width: "100px" }}
                      disabled={viewInv}
                    />
                    <Form.Item>
                      <Input
                        value={discountType}
                        style={{ width: "40px" }}
                        disabled={true}
                        placeholder={"%"}
                        // options={[
                        //   { label: "%", value: "%" },
                        //   { label: "Flat", value: "flat" },
                        // ]}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item label="Tax">
                  <Input.Group compact>
                    <Input
                      value={tax}
                      onChange={handleTaxChange}
                      style={{ width: "100px" }}
                      disabled={viewInv}
                    />
                    <Form.Item>
                      <Input
                        value={discountType}
                        style={{ width: "40px" }}
                        disabled={true}
                        placeholder={"%"}
                        // options={[
                        //   { label: "%", value: "%" },
                        //   { label: "Flat", value: "flat" },
                        // ]}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </div>
            ) : (
              <div
                style={{
                  fontSize: "1.1rem",
                  fontWeight: 600,
                }}
              >
                Invoice Details
              </div>
            )}
            <div className="totals" style={{ marginTop: 6 }}>
              <p>
                {viewInv && (
                  <>
                    <span>Payment Status:</span>
                    <span>Payment Date:</span>
                    <span>Invoice Date:</span>
                  </>
                )}
                <span>Credit Qty:</span>
                <span>Sub Total Amount:</span>
                <span>Discount{discount ? `(${discount}%)` : ""}:</span>
                <span>Tax Amount{tax ? `(${tax}%)` : ""}:</span>
                <span>Total Amount:</span>
              </p>
              <p className="values">
                {viewInv && (
                  <>
                    <span className={`status_${record?.status}`}>
                      {record?.status?.replace(/_/g, " ")}
                    </span>
                    <span>
                      {record?.receipts?.[0]?.payment_date
                        ? moment(record?.receipts?.[0]?.payment_date).format(
                            "DD/MM/YYYY HH:mm a"
                          )
                        : "-"}
                    </span>
                    <span>
                      {moment(record?.invoice_date).format(
                        "DD/MM/YYYY HH:mm a"
                      )}
                    </span>
                  </>
                )}
                <span>{totalCredits}</span>
                <span>₹ {amountFormat(subTotal)}</span>
                <span>
                  (-) ₹ {amountFormat(discountAmount ? discountAmount : 0)}
                </span>
                <span>(+) ₹ {amountFormat(taxAmount ? taxAmount : 0)}</span>
                <span>₹ {amountFormat(totalAmount)}</span>
              </p>
            </div>
          </div>
        </div>
        {viewInv ? null : (
          <button
            type="button"
            className="primary-btn w100"
            onClick={() => {
              handleOk();
            }}
          >
            Create Invoice
          </button>
        )}
      </Form>
    </div>
  );
};

export default AddCredits;
