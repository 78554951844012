import { Button, Modal, Space, message } from "antd";
import moment from "moment";
import { default as React, useEffect } from "react";
import { useForm } from "react-hook-form";
import DateInput from "../Profiles/DateInput";
import FormInput from "../Profiles/FormInput";
import FormSelect from "../Profiles/FormSelect";
import usePayAxios from "../../config/usePayApis";
import { apis } from "../../config/APIs";

const RecordPayment = ({
  open,
  onClose,
  amount,
  invoice_id,
  company_id,
  invoice_no,
  onSuccess,
}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      amount_paid: amount,
      payment_date: moment(),
      payment_mode: "CASH",
      paid_through: "-",
      reference_number: "",
      notes: "",
    },
    shouldUseNativeValidation: false,
  });

  const { onCall } = usePayAxios({
    api: apis.recordPayment,
    method: "post",
  });

  const onSubmit = (data) => {
    onCall({
      data: {
        invoice_id,
        company_id,
        invoice_amount: amount,
        description: data?.notes,
        reference_no: data?.reference_number,
        payment_mode: data?.payment_mode,
        payment_date: data?.payment_date,
        invoice_no,
      },
    })
      .then((res) => {
        if (res?.error === false) {
          message.success(<span className="textMessage">{res?.message}</span>);
          onClose(true);
          onSuccess();
          reset()
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          message.error(
            <span className="textMessage">{err?.response?.data?.message}</span>
          );
        }
      });
  };

  useEffect(() => {
    setValue("amount_paid", amount);
  }, [amount]);
  //  CASH,CARD,CHEQUE,CREDIT,ONLINE,CASH_ON_PICKUP,OFFLINE
  const payment_mode = [
    {
      label: "Cheque",
      value: "CHEQUE",
    },
    {
      label: "Cash",
      value: "CASH",
    },
    {
      label: "Card",
      value: "CARD",
    },
  ];

  return (
    <>
      <Modal
        onCancel={() => {
          onClose();
          reset();
        }}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">{"Record Payment"}</span>
            </div>
          </div>
        }
        visible={open}
        footer={[]}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="p1">
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("amount_paid")}
                    errors={errors}
                    label="Amount"
                    placeholder={"Enter Amount"}
                    disabled={true}
                    prefix={"Rs."}
                  />
                </div>
                <div className="pp-form-item">
                  <DateInput
                    control={control}
                    {...register("payment_date")}
                    errors={errors}
                    label={"Payment date"}
                  />
                </div>
                <div className="pp-form-item">
                  <FormSelect
                    inline
                    control={control}
                    {...register("payment_mode", { required: true })}
                    required
                    errors={errors}
                    label={"Payment mode"}
                    placeholder={"select"}
                    options={payment_mode}
                    hideCreateOption={true}
                  />
                </div>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("reference_number")}
                    errors={errors}
                    label={"Reference"}
                  />
                </div>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("notes")}
                    errors={errors}
                    label={"Notes"}
                  />
                </div>
              </div>
            </div>

            <Space
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "6rem 47px 6rem 0px",
                justifyContent: "end",
              }}
            >
              <button
                className="secondary-btn"
                style={{ marginLeft: "2rem" }}
                onClick={() => {
                  onClose();
                  reset();
                }}
                type="button"
              >
                <span>{"Cancel"}</span>
              </button>

              <button type="primary" htmlType="submit" className="primary-btn">
                <span>{"Save"}</span>
              </button>
            </Space>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default RecordPayment;
