import { FormOutlined } from "@ant-design/icons";
import { Modal, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/auth";
import {
  AddAgent,
  Agent,
  agentsList,
  showAgent,
} from "../../actions/employees/employees";
import { hasAccess } from "../../utils";
import Table from "../Common/Table/Table";
import AddStaff from "./AddStaff";
import moment from "moment";

const Agents = () => {
  const [addStaffModal, setAddStaffModal] = useState();
  const [currntPage, setCurrentPage] = useState("");
  const data = useSelector((state) => state?.agents?.get_agents?.data?.data);
  const dataLoading = useSelector(
    (state) => state?.agents?.get_agents?.loading
  );
  const user = useSelector((state) => state?.agents?.show_agent?.data?.data);
  const totalRecords = useSelector(
    (state) => state?.agents?.get_agents?.data?.total_count
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(agentsList());

    dispatch(
      headerTitles({
        title: "Third Party Agents",
      })
    );
    dispatch({
      type: AddAgent.RESET,
    });

    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      //   render: text => <a href="/">{text}</a>,
    },

    {
      title: "Phone No",
      dataIndex: "primary_phone",
      key: "primary_phone",
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("DD-MM-YYYY, HH:mm A"),
    },
    hasAccess("staff_users", "write")
      ? {
          title: "Actions",
          dataIndex: "id",
          width: 100,
          key: "id",
          render: (text, record) =>
            record?.email !== localStorage.getItem("email") ? (
              <Space>
                <FormOutlined
                  className="cursor"
                  onClick={() => {
                    setAddStaffModal(true);
                    dispatch(showAgent({ id: text }));
                  }}
                />
              </Space>
            ) : null,
        }
      : {},
  ];

  return (
    <>
      <Table
        columns={columns}
        placeholder={"Search by Email, Name, Mobile No"}
        loading={dataLoading}
        data={Array.isArray(data) ? data : []}
        pagination={{
          total: totalRecords || 0,
          onChange: (pagee) =>
            dispatch(
              agentsList(
                { params: { page_number: pagee } },
                setCurrentPage({ page_number: pagee })
              )
            ),
        }}
        topFil={true}
        addBtn={hasAccess("staff_users", "write")}
        handleSearch={(val) => {
          if (val) dispatch(agentsList({ search_key: val }));
          else dispatch(agentsList());
        }}
        buttonText={"Add Agent"}
        isHeaderButton={hasAccess("staff_users", "write")}
        handleButtonFunc={() => {
          setAddStaffModal(true);
        }}
      />
      <Modal
        visible={addStaffModal}
        title={`${user?.id ? "Edit" : "Add"} Agent`}
        className="right-alinged-modal"
        footer={[]}
        onCancel={() => {
          setAddStaffModal(false);
          dispatch({ type: Agent.RESET });
        }}
      >
        {addStaffModal && (
          <AddStaff
            selectedData={user}
            pageNumbers={currntPage}
            onCancel={() => {
              setAddStaffModal(false);
              dispatch({ type: Agent.RESET });
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default Agents;
