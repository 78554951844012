import { Modal } from "antd";
import React from "react";

import CreateProfile from "../Profiles/CreateProfile";

const AddPlanModal = ({ visible, handleCancel, company_id, onSuccess }) => {
  return (
    <div>
      <Modal
        className="plan-modal right-alinged-modal"
        title={
          <div className="modal-title">
            <span className="modal-head">{"Create Plan"}</span>
            <span className="modal-subtitle">
              Create your customized plan.
            </span>
          </div>
        }
        visible={visible}
        onCancel={handleCancel}
        width={"100%"}
        footer={null}
      >
        <CreateProfile
          company_id={company_id}
          handleCancel={handleCancel}
          onSuccess={onSuccess}
        />
      </Modal>
    </div>
  );
};

export default AddPlanModal;
