import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getAllCountries,
  getStaffCompanyDetails,
  onboardPreview,
} from "../../actions/clients/clients";
import user from "../../assets/icons/no-image.png";

const Overview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const clientDetails = location?.state?.record;
  const compRes = useSelector(
    (state) => state?.client?.getStaffCmp?.data?.data
  );

  const allCountries = useSelector((state) =>
    state?.client?.getAllCountries?.data?.data?.map((item, index) => ({
      ...item,
      value: item?.country_iso_code,
      label: (
        <span
          style={{
            color: "#212121",
            fontSize: 15,
          }}
        >
          <img
            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_iso_code?.toLowerCase()}.svg`}
            className="mr1"
            width={25}
            style={{
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
            }}
          />
          {`${item?.country_name} (${item?.country_iso_code})`}
        </span>
      ),
    }))
  );

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  useEffect(() => {
    if (clientDetails?.id) {
      dispatch(getStaffCompanyDetails(clientDetails?.id));
    }
  }, [clientDetails]);

  return (
    <div className="pt2 loan-sec">
      <div className="applicant-details">
        <div className="promoter-details">
          <h3>Company Details</h3>
          <div className="flex-between">
            <div className="name-details">
              <div>
                <img
                  src={compRes?.logo?.url ?? user}
                  alt="avatar"
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div>
                <p>
                  <b>{clientDetails?.name}</b>
                </p>
                <p className="acc-stat">{clientDetails?.primary_email}</p>
                <p className="acc-stat">{clientDetails?.office_no}</p>
              </div>
            </div>
            <div className="details">
              <div className="item-detail">
                <label>Available Credits</label>
                <p>{compRes?.credits_remaining ?? 0}</p>
              </div>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Primary Contact Name</label>
              <p>{compRes?.primary_contact_name}</p>
            </div>
            <div className="item-detail">
              <label>Primary Contact Email</label>
              <p>{compRes?.primary_contact_email}</p>
            </div>
            <div className="item-detail">
              <label>Primary Contact Phone</label>
              <p>{compRes?.primary_phone}</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Support Email</label>
              <p>{compRes?.support_email}</p>
            </div>
            <div className="item-detail">
              <label>Support Mobile Number</label>
              <p>{compRes?.support_number}</p>
            </div>
            <div className="item-detail">
              <label>Has Custom Price Plan ?</label>
              <p>{compRes?.custom_price_plan ? "Yes" : "No"}</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Is KYC Completed ?</label>
              <p>{compRes?.kyb_completed ? "Yes" : "No"}</p>
            </div>
            <div className="item-detail">
              <label>Company Registration No</label>
              <p>{compRes?.registration_no}</p>
            </div>
            <div className="item-detail">
              <label>No..of Employees</label>
              <p>{compRes?.no_of_employees ? compRes?.no_of_employees : "-"}</p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>GST No.</label>
              <p>{compRes?.gst_no ? compRes?.gst_no : "-"}</p>
            </div>
            <div className="item-detail">
              <label>TAN No.</label>
              <p>{compRes?.tan_no ? compRes?.tan_no : "-"}</p>
            </div>
            <div className="item-detail">
              <label>Year of Incorporation</label>
              <p>
                {compRes?.year_of_incorporation
                  ? compRes?.year_of_incorporation
                  : "-"}
              </p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Company PAN</label>
              <p>{compRes?.pan_number ? compRes?.pan_number : "-"}</p>
            </div>
            <div className="item-detail">
              <label>Bussiness Type</label>
              <p style={{ textTransform: "capitalize" }}>
                {compRes?.business_entity_type
                  ? compRes?.business_entity_type
                  : "-"}
              </p>
            </div>
            <div className="item-detail">
              <label>GST Certificate</label>
              <p>
                {compRes?.gst_certificate?.url ? (
                  <a href={compRes?.gst_certificate?.url} target="_blank">
                    Preview
                  </a>
                ) : (
                  "-"
                )}
              </p>
            </div>
          </div>
          <div className="details">
            <div className="item-detail">
              <label>Incorporation Certificate</label>
              <p>
                {compRes?.incorporation?.url ? (
                  <a href={compRes?.incorporation?.url} target="_blank">
                    Preview
                  </a>
                ) : (
                  "-"
                )}
              </p>
            </div>
            <div className="item-detail"></div>
            <div className="item-detail"></div>
          </div>
        </div>
        <div className="line" />
        <h3>Address Details</h3>
        <div className="details">
          <div className="item-detail">
            <label>Address</label>
            <p>{compRes?.address}</p>
          </div>
          <div className="item-detail">
            <label>City</label>
            <p>{compRes?.city}</p>
          </div>
          <div className="item-detail">
            <label>Pincode</label>
            <p>{compRes?.pincode}</p>
          </div>
          <div className="item-detail">
            <label>State</label>
            <p>{compRes?.state}</p>
          </div>
        </div>
        <div className="details">
          <div className="item-detail">
            <label>Country</label>
            <p>
              {
                allCountries?.find((ele) => ele.value === compRes?.country)
                  ?.label
              }
            </p>
          </div>
        </div>
        <div className="line" />
        <h3>Settings</h3>
        <div className="details">
          <div className="item-detail">
            <label>Applicant Prefix</label>
            <p>
              {compRes?.candidate_module_prefix +
                compRes?.candidate_module_display_starts_with}
            </p>
          </div>
          <div className="item-detail"></div>
          <div className="item-detail"></div>
          <div className="item-detail"></div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
