import { Button, Modal, Upload } from "antd";
import React, { useState } from "react";
import attachment from "../../../assets/icons/attachment.svg";
import cloud from "../../../assets/icons/cloud.svg";
import delete_cross from "../../../assets/icons/delete_cross_icon.svg";
import preview_icon from "../../../assets/icons/preview_icon.svg";
import pdf_icon from "../../../assets/icons/pdf_icon.png";
import { getFileExtension } from "../../../utils";

const UploadNew = ({
  label,
  required,
  accept,
  beforeUpload,
  onChange,
  multiple = false,
  disabled = false,
  name,
  errorMessage,
  className,
  hasImg,
  fileName,
  url,
  handleRemove,
  fileSize,
}) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
  });
  return hasImg ? (
    <div className="business_image_section mt3">
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{
                width: "100%",
                height: "90vh",
                margin: "auto",
                marginTop: "2rem",
              }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div className="flex-center">
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxHeight: "85vh", margin: "auto", marginTop: "2rem" }}
            />
          </div>
        )}
      </Modal>
      <div className="mb1 upload-sec upload-show">
        <div className="left_section d-flex">
          <div className="image_section mr1">
            <img
              src={getFileExtension(fileName) === "pdf" ? pdf_icon : url}
              style={{
                height: "7.5rem",
              }}
            />
          </div>
          <div className="image_name">
            <div>
              <h4 className="file_name">{fileName}</h4>

              <span className="sub-title">
                File Format: {getFileExtension(fileName)}
              </span>
            </div>
          </div>
        </div>
        <div className="right_section">
          <div className="active_section d-flex">
            <div
              className="preview_section mr1"
              onClick={() => {
                setIsPreviewOpen({
                  open: true,
                  fileType: getFileExtension(fileName),
                  url: url,
                });
              }}
            >
              <img
                src={preview_icon}
                alt="preview-icon"
                className="preview_img w100 h100"
              />
            </div>
            <div
              className="delete_section"
              onClick={() => {
                handleRemove();
              }}
            >
              <img
                src={delete_cross}
                alt="delete-icon"
                className="delete_img w100 h100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`upload-sec ${className ? className : ""}`}>
      <div className="d-flex align-center">
        <img
          src={cloud}
          className="cloud"
          width={45}
          style={{
            marginLeft: 8,
          }}
        />
        <div className="d-flex flex-col ml1">
          <span className="title">
            {label}
            {required && (
              <sup
                style={{
                  color: "red",
                  fontSize: "14px",
                  position: "relative",
                  top: "-1px",
                }}
              >
                *
              </sup>
            )}
          </span>
          <span
            className="supported"
            style={{
              marginTop: 5,
              fontSize: 12,
            }}
          >
            Files Supported:{" "}
            {Array.isArray(accept)
              ? accept?.join(", ")
              : "JPEG, PDF and PNG (max size 2mb)"}
          </span>
          {fileSize ? (
            <span
              className="supported"
              style={{
                marginTop: 5,
                fontSize: 12,
              }}
            >
              Max File Size: {(fileSize / (1024 * 1024)).toFixed(2)} MB
            </span>
          ) : null}
        </div>
      </div>
      <div>
        <Upload
          showUploadList={false}
          maxCount={1}
          beforeUpload={beforeUpload}
          className="upload_file"
          onChange={onChange}
          name={name}
          // value={value ? value : ""}
          // maxCount={1}
          multiple={multiple}
          accept={accept}
          disabled={disabled}
        >
          <button type="button" className="upload_buttom">
            Attach File
            <img
              src={attachment}
              width={16}
              style={{
                marginLeft: 8,
              }}
            />
          </button>
        </Upload>
        {errorMessage ? <div className="errorMsg">{errorMessage}</div> : null}
      </div>
    </div>
  );
};

export default UploadNew;
