import { ReducerFunctions } from "../../utils";
import {
  CREATE_PAGE_PROFILE,
  DELETE_PAGE_PROFILE,
  GET_ALL_PAGE_PROFILES,
  GET_PAGE_PROFILE_BY_ID,
  GetProfileScore,
  HeaderTitles,
  Login,
  Logout,
  UPDATE_PAGE_PROFILE,
  UpdateProfileScore,
} from "./auth";

export const login_reducer = ReducerFunctions(Login, {});
export const logout_reducer = ReducerFunctions(Logout, {});
export const local_varaibles = (
  state = {
    is_admin: "",
    policyViolations: [],
    policyModal: false,
    language: "en",
  },
  action
) => {
  switch (action.type) {
    case HeaderTitles:
      return {
        ...state,
        titles: action.payload,
      };
    default:
      return state;
  }
};

export const createPageProfileReducer = ReducerFunctions(
  CREATE_PAGE_PROFILE,
  {}
);

export const updatePageProfileReducer = ReducerFunctions(
  UPDATE_PAGE_PROFILE,
  {}
);

export const getAllPageProfilesReducer = ReducerFunctions(
  GET_ALL_PAGE_PROFILES,
  {}
);

export const getPageProfileByIDReducer = ReducerFunctions(
  GET_PAGE_PROFILE_BY_ID,
  {}
);

export const deletePageProfileReducer = ReducerFunctions(
  DELETE_PAGE_PROFILE,
  {}
);

export const update_profile_score_reducer = ReducerFunctions(
  UpdateProfileScore,
  {}
);

export const get_profile_score_reducer = ReducerFunctions(GetProfileScore, {});
