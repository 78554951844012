import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../actions/auth";
import {
  getCompanyList,
  getStaffCompanyDetails,
} from "../../actions/clients/clients";
import TableComponent from "../Common/Table/Table";
import { amountFormat } from "../Profiles/Plan";
import RecordPayment from "./RecordPaymentModal";
import _ from "lodash";
import moment from "moment";
import checkbook from "../../assets/icons/checkbook.svg";
import eye from "../../assets/icons/eye.png";
import AddCreditsModal from "../Profiles/CreditModal2";

const Invoices = ({ company_id, getAllInvoices, data }) => {
  const [selectedRecord, setSelectedRecord] = useState({});
  const [visible, setVisible] = useState(false);
  const [viewInv, setViewInv] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Invoices",
      })
    );

    dispatch(getCompanyList({}));
    getAllInvoices({
      params: { company_id },
    });
  }, []);

  const showModal = (id) => {
    setVisible(true);
  };

  const columns = [
    {
      title: "Invoice No.",
      dataIndex: "invoice_no",
      key: "invoice_no",
    },
    {
      title: "Credits",
      dataIndex: "credits",
      key: "credits",
      render: (text, record) => (
        <div>{text ? text : _.sumBy(record?.items, "quantity")}</div>
      ),
      width: 100,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <div>Rs. {amountFormat(text)}</div>,
    },
    {
      title: "Discount",
      dataIndex: "discount_amount",
      key: "discount_amount",
      render: (text) => <div>Rs. {amountFormat(text)}</div>,
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text) => <div>Rs. {amountFormat(text)}</div>,
    },
    {
      title: "Reference",
      dataIndex: "receipts",
      key: "receipts",
      render: (text) => (
        <div>{text?.[0]?.reference_no ? text?.[0]?.reference_no : "-"}</div>
      ),
    },
    {
      title: "Description",
      dataIndex: "receipts",
      key: "receipts",
      render: (text) => (
        <div>{text?.[0]?.description ? text?.[0]?.description : "-"}</div>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text) => moment(text).format("DD-MM-YYYY, HH:mm A"),
    },
    {
      title: "Payment Date",
      dataIndex: "receipts",
      key: "receipts",
      render: (text) => (
        <div>
          {text?.[0]?.payment_date
            ? moment(text?.[0]?.payment_date).format("DD-MM-YYYY, HH:mm A")
            : "-"}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <div className={`status status_${text?.toLowerCase()} `}>
          {text?.replace(/_/g, " ")}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "title",
      key: "title",
      fixed: "right",
      width: 210,
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            title="View Invoice"
            onClick={() => {
              setViewInv(true);
              setSelectedRecord(record);
            }}
          >
            <img src={eye} width={25} />
          </button>
          {record?.status !== "paid" && (
            <button
              onClick={() => {
                showModal(record.id);
                setSelectedRecord(record);
              }}
              title="Record Payment"
            >
              <img src={checkbook} width={30} />
            </button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="pt1">
      <RecordPayment
        open={visible}
        onClose={(call) => {
          setVisible(false);
          setSelectedRecord({});
          if (call)
            getAllInvoices({
              params: { company_id },
            });
        }}
        amount={selectedRecord?.total_amount}
        invoice_id={selectedRecord?.id}
        invoice_no={selectedRecord?.invoice_no}
        company_id={company_id}
        onSuccess={() => {
          dispatch(getStaffCompanyDetails(company_id));
          getAllInvoices({
            params: { company_id },
          });
        }}
      />
      {viewInv && (
        <AddCreditsModal
          visible={viewInv}
          handleCancel={() => {
            setViewInv(false);
          }}
          company_id={company_id}
          record={selectedRecord}
          viewInv={viewInv}
        />
      )}
      <TableComponent
        rowKey="id"
        columns={[...columns]}
        data={data?.data ?? []}
        hasCheckbox={false}
        scroll={{ x: 1500 }}
        className="settingsTable"
        pagination={{
          total: data?.total_count || 0,
          onChange: (pagee) =>
            getAllInvoices({ params: { page_number: pagee, company_id } }),
        }}
      />
    </div>
  );
};

export default Invoices;
