import { EyeFilled } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import Text from "antd/lib/typography/Text";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import {
  AddStaffCompany,
  OnboardPreview,
  StaffCompanyDetails,
  addStaffCompany,
  getAllCountries,
  getStaffCompanyDetails,
  onboardPreview,
} from "../../../actions/clients/clients";
import png_icon from "../../../assets/icons/pdf_file_icon.png";
import { apis } from "../../../config/APIs";
import usePayAxios from "../../../config/usePayApis";
import { base64ToBlob, getFileExtension } from "../../../utils";
import PasswordCheck from "../../Common/PasswordCheck";
import "../clients.css";
import UploadNew from "./UploadNew";
import axios from "axios";
const { TextArea } = Input;
const { Title } = Typography;
const { Dragger } = Upload;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const OrganisationProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const addStaffCompRes = useSelector(
    (state) => state?.client?.addStaffCompany
  );

  const { onCall: DocumentDelete, loading: isDelLoading } = usePayAxios({
    api: apis?.deleteDocument,
    method: "delete",
    baseURL: "document",
  });

  const { onCall: DocumentAttID, loading: isUploadLoading } = usePayAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });

  const compRes = useSelector(
    (state) => state?.client?.getStaffCmp?.data?.data
  );

  const onboardPreviewResponse = useSelector(
    (state) => state?.client?.onboardPreview?.data
  );
  const allCountries = useSelector((state) =>
    state?.client?.getAllCountries?.data?.data?.map((item, index) => ({
      ...item,
      value: item?.country_iso_code,
      label: (
        <span
          style={{
            color: "#212121",
            fontSize: 15,
          }}
        >
          <img
            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_iso_code?.toLowerCase()}.svg`}
            className="mr1"
            width={25}
            style={{
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
            }}
          />
          {`${item?.country_name} (${item?.country_iso_code})`}
        </span>
      ),
    }))
  );
  const location = useLocation();

  const [isAuto, setIsAuto] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);
  const [fileDetails, setFileDetails] = useState({});

  const [rejectModal, setRejectModal] = useState(false);
  const [rejectText, setRejectText] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
  });

  const OrgProfileSchema = Yup.lazy((vals) => {
    let obj = {
      name: Yup.string().required("Name is required."),
      virtual_id: Yup.string()
        .nullable()
        .trim()
        .test(
          "is-virtual-id",
          `vertual ID is required and must be 16 characters`,
          function (value) {
            const { path, createError, originalValue } = this;
            if (
              originalValue &&
              (originalValue.length < 16 || originalValue.length > 16)
            ) {
              return createError({
                path,
                message: `vertual ID is required and must be 16 characters`,
              });
            } else {
              return true;
            }
          }
        ),
      // outlet_id: Yup.string().required("Outlet ID is required."),
      registration_no: Yup.string()
        .nullable()
        .required("Registration no is required."),
      logo: Yup.mixed(),
      address: Yup.string().nullable().required("Address is required."),
      city: Yup.string().nullable().matches("^[A-Za-z ]*$", "Enter valid city"),
      state: Yup.string()
        .nullable()
        .matches("^[A-Za-z ]*$", "Enter valid state"),
      country: Yup.string()
        .nullable()
        .matches("^[A-Za-z ]*$", "Enter valid country")
        .required("Country is required."),
      pincode: Yup.string()
        .nullable()
        .matches("^[0-9]{5,8}$", "Enter valid pincode")
        .required("pincode is required")
        .min(6, "enter Valid pincode"),
      office_no: Yup.string().nullable().required("Office no is required."),
      gst_no: Yup.string()
        .nullable()
        .matches("^[A-Z0-9]{9,20}$", "Enter valid GST No"),

      tan_no: Yup.string()
        .nullable()
        .matches("^[A-Z0-9]{9,20}$", "Enter valid TAN No"),
      primary_contact_name: Yup.string()
        .nullable()
        .required("Primary contact name is required"),
      primary_email: Yup.string()
        .nullable()
        .email("Enter valid primary email.")
        .required("Primary email is required."),
      support_number: Yup.string()
        .nullable()
        .required("Support mobile number name is required"),
      support_email: Yup.string()
        .nullable()
        .email("Enter valid primary email.")
        .required("Support email is required."),
      email: Yup.string()
        .nullable()
        .email("Enter valid email.")
        .required("email is required."),
      primary_phone: Yup.string()
        .nullable()
        .required("Primary phone is required.")
        .min(10, "minimum 10 digits required")
        .max(12, "max 12 digits"),
      password: Yup.string().nullable().required("password is required"),
      password_confirmation: Yup.string()
        .nullable()
        .required("confirmation password is required"),
      year_of_incorporation: Yup.string().required(
        "Year of Incorporation is required"
      ),
      no_of_employees: Yup.string().required("Number of Employees is required"),
      pan_number: Yup.string().required("PAN Number is required"),
      business_entity_type: Yup.string()
        .nullable()
        .required("Business Entity Type is required"),
    };
    if (!isAuto && !vals.id) {
      obj = {
        ...obj,
        password_confirmation: Yup.string()
          .nullable()
          .required("Password confirmation is required.")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
        password: Yup.string().nullable().required("Password is required."),
      };
    } else {
      if (obj.password && obj.password_confirmation) {
        delete obj.password;
        delete obj.password_confirmation;
      }
    }
    return Yup.object().shape(obj);
  });

  const initialObj = {
    name: "",
    primary_email: "",
    primary_contact_name: "",
    registration_no: "",
    logo: "",
    address: "",
    city: "",
    state: "",
    country: "IN",
    pincode: "",
    office_no: "",
    gst_no: "",
    tan_no: "",
    email: "",
    primary_phone: "",
    password_confirmation: "",
    password: "",
    mo_id: "",
    virtual_id: "",
    outlet_id: "",
    fiscal_year: "FINANCIALYEAR",
    custom_price_plan: false,
    candidate_module_prefix: "",
    candidate_module_starts_with: "",
    year_of_incorporation: "",
    no_of_employees: "",
    pan_number: "",
    business_entity_type: null,
    support_email: "",
    support_number: "",
  };

  const formik = useFormik({
    initialValues: initialObj,
    validationSchema: OrgProfileSchema,
    onSubmit: (vals) => {
      let obj = { ...vals };
      const arr = [];
      const arrDel = [];

      const callOnSave = (fileData) => {
        if (isAuto) {
          delete obj.password;
          delete obj.password_confirmation;
        } else {
          delete obj.password_confirmation;
        }

        obj.logo = fileData?.logo
          ? fileData?.logo
          : fileDetails?.logo?.url
          ? [fileDetails?.logo?.attachId]
          : null;
        obj.incorporation = fileData?.incorporation
          ? fileData?.incorporation
          : fileDetails?.incorporation?.url
          ? [fileDetails?.incorporation?.attachId]
          : null;
        obj.gst_certificate = fileData?.gst_certificate
          ? fileData?.gst_certificate
          : fileDetails?.gst_certificate?.url
          ? [fileDetails?.gst_certificate?.attachId]
          : null;
        if (compRes?.id) {
          obj.id = compRes?.id;
        }
        dispatch(addStaffCompany(obj));
      };

      if (fileDetails?.logo?.formData) {
        arr.push({
          type: "logo",
          params: compRes?.id ? { record_id: compRes?.id } : {},
          data: fileDetails?.logo.formData,
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }

      if (fileDetails?.incorporation?.formData) {
        arr.push({
          type: "incorporation",
          params: compRes?.id ? { record_id: compRes?.id } : {},
          data: fileDetails?.incorporation.formData,
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }

      if (fileDetails?.gst_certificate?.formData) {
        arr.push({
          type: "gst_certificate",
          params: compRes?.id ? { record_id: compRes?.id } : {},
          data: fileDetails?.gst_certificate.formData,
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }

      if (fileDetails?.logo_rem?.removeId) {
        arrDel.push({
          type: "logo",
          data: {
            ids: [fileDetails?.logo_rem?.removeId],
          },
          params: {
            record_id: compRes?.id,
          },
        });
      }

      if (fileDetails?.incorporation_rem?.removeId) {
        arrDel.push({
          type: "incorporation",
          data: {
            ids: [fileDetails?.incorporation_rem?.removeId],
          },
          params: {
            record_id: compRes?.id,
          },
        });
      }

      if (fileDetails?.gst_certificate_rem?.removeId) {
        arrDel.push({
          type: "gst_certificate",
          data: {
            ids: [fileDetails?.gst_certificate_rem?.removeId],
          },
          params: {
            record_id: compRes?.id,
          },
        });
      }

      Promise.all([
        ...arr.map((ele) =>
          DocumentAttID(ele).then((response) => ({
            [ele.type]: response,
          }))
        ),
        ...arrDel.map((ele) =>
          DocumentDelete(ele).then((response) => ({
            [ele.type + "_rem"]: response,
          }))
        ),
      ]).then((results) => {
        const extractedData = {};
        results.forEach((response) => {
          const key = Object.keys(response)[0];
          if (!key?.includes("_rem")) extractedData[key] = response[key].data;
        });

        callOnSave(extractedData);
      });
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    dispatch(getAllCountries());
    return () => {
      dispatch({ type: OnboardPreview.RESET });
    };
  }, []);

  useEffect(() => {
    if (addStaffCompRes?.success) {
      message.success(addStaffCompRes?.data?.message);
    }
    if (addStaffCompRes?.success === true) {
      dispatch({
        type: AddStaffCompany.RESET,
      });
      props.history.push("/admin/clients");
    }

    if (addStaffCompRes?.success === false) {
      message.error(
        addStaffCompRes?.data?.errors ?? addStaffCompRes?.data?.message
      );
      dispatch({
        type: AddStaffCompany.RESET,
      });
    }
    // eslint-disable-next-line
  }, [addStaffCompRes]);

  useEffect(() => {
    if (location?.state && location?.state?.id) {
      dispatch(getStaffCompanyDetails(location?.state?.id));
      // dispatch(
      //   onboardPreview({
      //     company_id: location?.state?.id,
      //     params: { preview: true, country: location?.state?.country },
      //   })
      // );
      // dispatch(uploadDocument({params:{id:location?.state?.id}}))
    }
    // eslint-disable-next-line
  }, [location?.state]);

  useEffect(() => {
    if (compRes && compRes?.id) {
      delete compRes.account_ifsc;
      delete compRes?.account_name;
      delete compRes?.account_number;
      delete compRes?.bank_address;
      delete compRes?.bank_branch;
      delete compRes?.base_currency_id;
      delete compRes?.bank_name;

      formik.setValues({
        ...initialObj,
        ...compRes,
        vendor_id: compRes?.card_vendors?.card_vendor_id,
        candidate_module_starts_with:
          compRes?.candidate_module_display_starts_with,
        logo: [compRes?.logo?.id],
      });
      formik.setFieldValue("email", compRes?.primary_contact_email);

      if (compRes?.logo) {
        setFileDetails({
          logo: {
            url: compRes?.logo?.url,
            fileName: compRes?.logo?.file_name,
            attachId: compRes?.logo?.id,
            record_id: compRes?.id,
          },
          incorporation: {
            url: compRes?.incorporation?.url,
            fileName: compRes?.incorporation?.file_name,
            attachId: compRes?.incorporation?.id,
            record_id: compRes?.id,
          },
          gst_certificate: {
            url: compRes?.gst_certificate?.url,
            fileName: compRes?.gst_certificate?.file_name,
            attachId: compRes?.gst_certificate?.id,
            record_id: compRes?.id,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [compRes]);

  const onFileChange = async (info, type) => {
    getBase64(info.file, (url) => {
      let uniqueNo = fileDetails?.[type]?.record_id
        ? fileDetails?.[type]?.record_id
        : uuidv4();

      const formData = new FormData();

      formData.append("files", info.file);
      formData.append("name", type);
      formData.append("record_type", "Company");
      formData.append("record_id", uniqueNo);
      const blob = base64ToBlob(
        url,
        ["pdf"]?.includes(getFileExtension(info?.file?.name)?.toLowerCase())
          ? "application/pdf"
          : "application/png"
      );
      const blobURL = URL.createObjectURL(blob);
      setFileDetails((fd) => ({
        ...fd,
        [type]: {
          url: blobURL,
          fileName: info.file.name,
          formData: formData,
          record_id: uniqueNo,
        },
      }));
    });
  };

  const checkFile = (file, name, fileList = []) => {
    const fileName = getFileExtension(file?.name)?.toLowerCase();
    const sizes = {
      logo: 1572864,
      incorporation: 5242880,
      gst_certificate: 5242880,
    };
    if (
      fileList?.length
        ? fileList?.includes(fileName)
        : ["jpg", "jpeg", "png", "pdf"]?.includes(fileName)
    ) {
      if (file?.size > sizes[name]) {
        message.error(
          <span className="messageText">
            {`Please Upload file less than ${sizes[name] / (1024 * 1024)} MB`}
          </span>
        );
        return Upload.LIST_IGNORE;
      }
      return false;
    } else {
      message.error(
        <span className="messageText">{"Please Upload jpg or png files"}</span>
      );
      return Upload.LIST_IGNORE;
    }
  };
  useEffect(() => {
    return () => {
      formik.setValues(initialObj);
      setFileDetails({});
      dispatch({
        type: StaffCompanyDetails.RESET,
      });
    };
    // eslint-disable-next-line
  }, []);

  const {
    onCall: clientApproval,
    data: clientApprovalData,
    reset: approvalReset,
  } = usePayAxios({ api: apis?.onboardClientApproval, method: "post" });
  const {
    onCall: clientReject,
    data: clientRejectData,
    reset: rejectReset,
  } = usePayAxios({ api: apis?.onboardClientReject, method: "post" });

  useEffect(() => {
    if (
      clientApprovalData?.error == false ||
      clientRejectData?.error == false
    ) {
      message.success(
        <span className="messageText">
          {clientApprovalData?.data?.message ?? clientRejectData?.data?.message}
        </span>
      );
      rejectReset();
      approvalReset();
      history.push("/admin/clients/");
    } else if (
      clientApprovalData?.error == true ||
      clientRejectData?.error == true
    ) {
      message.error(
        <span className="messageText">
          {clientApprovalData?.data?.message ?? clientRejectData?.data?.message}
        </span>
      );
      rejectReset();
      approvalReset();
    }
  }, [clientRejectData, clientApprovalData]);

  const hiddenKeys = [
    "pan_number",
    "cin_number",
    "year_of_incorporation",
    "business_entity_type",
    "industry_type",
    "no_of_employees",
    "business_website_link",
    "address_line_two",
    "url",
    "card_number",
    "name_on_card",
    "dob",
    "incorporation",
    "gst_certificate",
    "upload_aadhaar_front",
    "upload_aadhaar_back",
    "upload_pan",
  ];

  const handleRemove = (type) => {
    setFileDetails((fd) => ({
      ...fd,
      [type + "_rem"]: { removeId: fileDetails?.[type]?.attachId },
      [type]: {},
    }));
    formik.setFieldValue(type, "");
  };
  const searchRef = useRef();
  const handleSearch = (value) => {
    formik.setFieldValue("pincode", value);
    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }
    searchRef.current = setTimeout(() => {
      axios
        .get(
          "https://api.worldpostallocations.com/pincode?postalcode=" +
            value +
            "&countrycode=" +
            formik.values.country +
            ""
        )
        .then((response) => {
          const output = response?.data?.result?.[0]
            ? response?.data?.result?.[0]
            : {};
          formik?.setFieldValue("state", output?.state);
          formik?.setFieldValue("city", output?.district);
        });
    }, 500);
  };
  const bus_type = [
    { id: "public", label: "Public", value: "public" },
    { id: "private", label: "Private", value: "private" },
  ];

  return (
    <>
      {(isUploadLoading || isDelLoading) && (
        <div className="text-center loadingDiv">
          <div class="pulse-dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      )}
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{
                width: "100%",
                height: "90vh",
                margin: "auto",
                marginTop: "2rem",
              }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div className="flex-center">
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxHeight: "85vh", margin: "auto", marginTop: "2rem" }}
            />
          </div>
        )}
      </Modal>
      <Modal
        visible={rejectModal}
        title="Rejecting OnBoard Client"
        footer={[]}
        closable={false}
      >
        <TextArea
          name="address"
          value={rejectText}
          placeholder="Enter your reason"
          autoSize={{ minRows: 3, maxRows: 5 }}
          className="inputTag"
          onChange={(e) => {
            setRejectText(e?.target?.value);
          }}
        />
        <div className="d-flex">
          <button
            disabled={!rejectText}
            className="reject mt1"
            onClick={() => {
              clientReject({
                data: { company_id: compRes?.id, reject_reason: rejectText },
              });
            }}
          >
            Reject
          </button>
          <button
            className="cancel ml1 mt1"
            onClick={() => {
              setRejectModal(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
        <div className="flex-between">
          <Title level={5} className="cardTitle" style={{ marginBottom: 0 }}>
            Organisation Profile
          </Title>
          {!compRes?.is_onboard_client &&
          !compRes?.kyb_completed &&
          compRes?.kyc_status === "kyc_submitted" ? (
            <div style={{ position: "relative", right: 25 }}>
              <button
                key="1"
                htmlType="button"
                className="approve"
                style={{ width: "100px" }}
                onClick={() => {
                  clientApproval({ data: { company_id: compRes?.id } });
                }}
              >
                Approve
              </button>
              <button
                className="reject ml1"
                onClick={() => {
                  setRejectModal(true);
                }}
                style={{ width: "100px" }}
              >
                Reject
              </button>
            </div>
          ) : null}
        </div>
        <Divider />
        <Form
          layout="vertical"
          onFinish={formik.handleSubmit}
          className="settings_form"
        >
          <Row>
            <Col span={12}>
              <Form.Item label="Company Name" required>
                <Input
                  placeholder="Company Name"
                  className="inputTag"
                  name="name"
                  value={formik.values["name"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="err-msg">{formik.errors.name}</div>
                )}
              </Form.Item>
              <Form.Item label="Primary Company Email" required>
                <Input
                  placeholder="Primary Company Email"
                  disabled={location?.state?.id}
                  className="inputTag"
                  name="primary_email"
                  value={formik.values["primary_email"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.primary_email &&
                  formik.errors.primary_email && (
                    <div className="err-msg">{formik.errors.primary_email}</div>
                  )}
              </Form.Item>
              <Form.Item label="Company Registration No" required>
                <Input
                  placeholder="Company Registration No"
                  className="inputTag"
                  name="registration_no"
                  value={formik.values["registration_no"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.registration_no &&
                  formik.errors.registration_no && (
                    <div className="err-msg">
                      {formik.errors.registration_no}
                    </div>
                  )}
              </Form.Item>
              <Form.Item label="Address" required>
                <TextArea
                  name="address"
                  value={formik.values["address"]}
                  // onChange={this.onChange}
                  placeholder="Enter Address"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="inputTag"
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9$&,:;@#.-/ ]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue("address", e.target.value);
                  }}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="err-msg">{formik.errors.address}</div>
                )}
              </Form.Item>
              <Form.Item label="Country" required>
                <Select
                  name="country"
                  className="inputTag selectTag"
                  placeholder="Select Country"
                  value={formik.values["country"]}
                  onChange={(val) => {
                    formik.setFieldValue("country", val);
                  }}
                  options={allCountries}
                  allowClear
                />
                {/* </Select> */}
                {formik.touched.country && formik.errors.country && (
                  <div className="err-msg">{formik.errors.country}</div>
                )}
              </Form.Item>
              <Form.Item label="Pincode" name="pincode" required>
                <Input
                  placeholder="Pincode"
                  className="inputTag"
                  name="pincode"
                  value={formik.values["pincode"]}
                  maxLength={8}
                  onChange={(e) => {
                    if (
                      !/^[0-9A-Za-z]*$/.test(e.target.value) &&
                      e.target.value
                    ) {
                      return;
                    }
                    handleSearch(e.target.value?.toLowerCase());
                  }}
                />
                {formik.touched.pincode && formik.errors.pincode && (
                  <div className="err-msg">{formik.errors.pincode}</div>
                )}
              </Form.Item>
              <Form.Item label="City" name="city">
                <Input
                  placeholder="City"
                  className="inputTag"
                  name="city"
                  value={formik.values["city"]}
                  onChange={(e) => {
                    if (
                      !/^[A-Za-z ]*$/.test(e.target.value) &&
                      e.target.value
                    ) {
                      return;
                    }
                    formik.setFieldValue("city", e.target.value);
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="err-msg">{formik.errors.city}</div>
                )}
              </Form.Item>
              <Form.Item label="State" name="state">
                <Input
                  placeholder="State"
                  className="inputTag"
                  name="state"
                  value={formik.values["state"]}
                  onChange={(e) => {
                    if (
                      !/^[A-Za-z ]*$/.test(e.target.value) &&
                      e.target.value
                    ) {
                      return;
                    }
                    formik.setFieldValue("state", e.target.value);
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="err-msg">{formik.errors.state}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="uploadSection align-items-center">
              <UploadNew
                beforeUpload={(file) =>
                  checkFile(file, "logo", ["png", "jpeg", "jpg"])
                }
                onChange={(e) => onFileChange(e, "logo")}
                maxCount={1}
                showUploadList={false}
                name="logo"
                errorMessage={formik.touched.logo && formik.errors.logo}
                label={"Upload your company logo"}
                accept={["png", "jpeg", "jpg"]}
                fileSize={1572864}
                className="mt2"
                hasImg={fileDetails?.logo?.url}
                url={fileDetails?.logo?.url}
                fileName={fileDetails?.logo?.fileName ?? ""}
                handleRemove={() => handleRemove("logo")}
              />
              <div className="mb2"></div>
              <UploadNew
                beforeUpload={(file) => checkFile(file, "incorporation")}
                onChange={(e) => onFileChange(e, "incorporation")}
                maxCount={1}
                fileSize={5242880}
                showUploadList={false}
                name="incorporation"
                errorMessage={
                  formik.touched.incorporation && formik.errors.incorporation
                }
                label={"Upload your incorporation certificate"}
                accept={["png", "jpeg", "jpg", "pdf"]}
                className="mt2"
                hasImg={fileDetails?.incorporation?.url}
                url={fileDetails?.incorporation?.url}
                fileName={fileDetails?.incorporation?.fileName ?? ""}
                handleRemove={() => handleRemove("incorporation")}
              />
              <div className="mb2"></div>
              <UploadNew
                beforeUpload={(file) => checkFile(file, "gst_certificate")}
                onChange={(e) => onFileChange(e, "gst_certificate")}
                fileSize={5242880}
                maxCount={1}
                showUploadList={false}
                name="gst_certificate"
                errorMessage={
                  formik.touched.gst_certificate &&
                  formik.errors.gst_certificate
                }
                label={"Upload your gst certificate"}
                accept={["png", "jpeg", "jpg", "pdf"]}
                className="mt2"
                hasImg={fileDetails?.gst_certificate?.url}
                url={fileDetails?.gst_certificate?.url}
                fileName={fileDetails?.gst_certificate?.fileName ?? ""}
                handleRemove={() => handleRemove("gst_certificate")}
              />
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="Year of Incorporation" required>
                <Input
                  placeholder="Year of Incorporation"
                  className="inputTag"
                  name="year_of_incorporation"
                  maxLength={12}
                  value={formik.values["year_of_incorporation"]}
                  onChange={(e) => {
                    if (
                      (!/^[0-9]*$/.test(e.target.value) && e.target.value) ||
                      e.target.value?.length > 4
                    ) {
                      return;
                    }
                    formik.setFieldValue(
                      "year_of_incorporation",
                      e.target.value
                    );
                  }}
                />
                {formik.touched.year_of_incorporation &&
                  formik.errors.year_of_incorporation && (
                    <div className="err-msg">
                      {formik.errors.year_of_incorporation}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="No...of Employees" required>
                <Input
                  placeholder="No...of Employees"
                  className="inputTag"
                  name="no_of_employees"
                  maxLength={20}
                  value={formik.values["no_of_employees"]}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue(
                      "no_of_employees",
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.no_of_employees &&
                  formik.errors.no_of_employees && (
                    <div className="err-msg">
                      {formik.errors.no_of_employees}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Business PAN Number" required>
                <Input
                  placeholder="Business PAN Number"
                  className="inputTag"
                  name="pan_number"
                  maxLength={20}
                  value={formik.values["pan_number"]}
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue(
                      "pan_number",
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.pan_number && formik.errors.pan_number && (
                  <div className="err-msg">{formik.errors.pan_number}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="Office Number" name="office_no" required>
                <Input
                  placeholder="Office Number"
                  className="inputTag"
                  name="office_no"
                  maxLength={12}
                  value={formik.values["office_no"]}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("office_no", e.target.value);
                  }}
                />
                {formik.touched.office_no && formik.errors.office_no && (
                  <div className="err-msg">{formik.errors.office_no}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="GSTIN No">
                <Input
                  placeholder="GSTIN No"
                  className="inputTag"
                  name="gst_no"
                  maxLength={20}
                  value={formik.values["gst_no"]}
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue(
                      "gst_no",
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.gst_no && formik.errors.gst_no && (
                  <div className="err-msg">{formik.errors.gst_no}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="TAN No">
                <Input
                  placeholder="TAN No"
                  className="inputTag"
                  name="tan_no"
                  maxLength={20}
                  value={formik.values["tan_no"]}
                  onChange={(e) => {
                    if (!/^[A-Za-z0-9]*$/.test(e.target.value)) {
                      return;
                    }
                    formik.setFieldValue(
                      "tan_no",
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.tan_no && formik.errors.tan_no && (
                  <div className="err-msg">{formik.errors.tan_no}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="Business Type" required>
                <Select
                  name="business_entity_type"
                  className="inputTag selectTag"
                  placeholder="Select Business Type"
                  value={formik.values["business_entity_type"]}
                  onChange={(val) => {
                    formik.setFieldValue("business_entity_type", val);
                  }}
                  options={bus_type}
                  allowClear
                />
                {formik.touched.business_entity_type &&
                  formik.errors.business_entity_type && (
                    <div className="err-msg">
                      {formik.errors.business_entity_type}
                    </div>
                  )}
              </Form.Item>
            </Col>
          </Row>
          <Title level={5} className="cardTitle">
            Primary Contact
          </Title>
          <Divider />
          <Row className="gap1">
            <Col span={6}>
              <Form.Item
                label="Primary Contact Name"
                // className="primary-contact"
                required
              >
                <Input
                  placeholder="Primary Contact Name"
                  className="inputTag"
                  name="primary_contact_name"
                  value={formik.values["primary_contact_name"]}
                  onChange={formik.handleChange}
                  disabled={location?.state?.id}
                />
                {formik.touched.primary_contact_name &&
                  formik.errors.primary_contact_name && (
                    <div className="err-msg">
                      {formik.errors.primary_contact_name}
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Primary Contact Number"
                name="contact_number"
                required
              >
                <Input
                  placeholder="Primary Contact Number"
                  className="inputTag"
                  name="primary_phone"
                  value={formik.values["primary_phone"]}
                  disabled={location?.state?.id}
                  maxLength={15}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("primary_phone", e.target.value);
                  }}
                />
                {formik.touched.primary_phone &&
                  formik.errors.primary_phone && (
                    <div className="err-msg">{formik.errors.primary_phone}</div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Contact Email" required>
                <Input
                  placeholder="Contact Email"
                  className="inputTag"
                  disabled={location?.state?.id}
                  name="email"
                  value={formik.values["email"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="err-msg">{formik.errors.email}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <div className="ant-form-item-label mt1 mb1">
                <label>
                  <Checkbox
                    disabled={location?.state?.id && compRes?.custom_price_plan}
                    name="custom_price_plan"
                    checked={formik.values["custom_price_plan"]}
                    onChange={formik.handleChange}
                    style={{
                      marginRight: 5,
                    }}
                  />
                  Enable Custom Plan
                </label>
              </div>
              {formik.touched.custom_price_plan &&
                formik.errors.custom_price_plan && (
                  <div className="err-msg">
                    {formik.errors.custom_price_plan}
                  </div>
                )}
            </Col>
          </Row>
          <Title level={5} className="cardTitle">
            Support Contact Details
          </Title>
          <Divider />
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="Support Email" required>
                <Input
                  placeholder="Support Email"
                  className="inputTag"
                  name="support_email"
                  value={formik.values["support_email"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.support_email &&
                  formik.errors.support_email && (
                    <div className="err-msg">{formik.errors.support_email}</div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Support Mobile Number" required>
                <Input
                  placeholder="Support Mobile Number"
                  className="inputTag"
                  name="support_number"
                  value={formik.values["support_number"]}
                  maxLength={15}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("support_number", e.target.value);
                  }}
                />
                {formik.touched.support_number &&
                  formik.errors.support_number && (
                    <div className="err-msg">
                      {formik.errors.support_number}
                    </div>
                  )}
              </Form.Item>
            </Col>
          </Row>

          {!formik.values?.id && (
            <>
              <Title level={5} className="cardTitle">
                Password
              </Title>
              <Divider />
              <Row>
                <Col>
                  <div className="radio-grp mb1">
                    <Input
                      type={"radio"}
                      name="isAuto"
                      autoComplete="new-password"
                      onChange={() => {
                        setIsAuto(true);
                        formik.setFieldValue("password", "");
                        formik.setFieldValue("password_confirmation", "");
                      }}
                      checked={isAuto}
                    />
                    <div>
                      <label>Automatically Generate Password</label>
                      <span>
                        Email will be sent to the client to set the password.
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="radio-grp">
                    <Input
                      type={"radio"}
                      checked={!isAuto}
                      name="isAuto"
                      onChange={() => {
                        setIsAuto(false);
                      }}
                    />
                    <label>Create Password</label>
                  </div>
                  {!isAuto && (
                    <Row className="gap1 mt1">
                      <Col span={6}>
                        <Form.Item
                          label="Password"
                          name="contact_number"
                          required
                        >
                          <Input.Password
                            placeholder="Password"
                            className="inputTag"
                            name="password"
                            autoComplete="new-password"
                            value={formik.values["password"]}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.password &&
                            formik.errors.password && (
                              <div className="err-msg">
                                {formik.errors.password}
                              </div>
                            )}
                          <PasswordCheck
                            password={formik.values.password}
                            dark
                            setPasswordCheck={setPasswordCheck}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Confirm Password"
                          name="password_confirmation"
                          required
                        >
                          <Input.Password
                            placeholder="Confirm Password"
                            className="inputTag"
                            allowClear={false}
                            name="password_confirmation"
                            value={formik.values["password_confirmation"]}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.password_confirmation &&
                            formik.errors.password_confirmation && (
                              <div className="err-msg">
                                {formik.errors.password_confirmation}
                              </div>
                            )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )}
          <>
            <Title level={5} className="cardTitle">
              Settings
            </Title>
            <Divider />
            <Row className="gap1">
              <Col span={6}>
                <Form.Item label="Applicant Prefix">
                  <Input
                    placeholder="Applicant Prefix"
                    className="inputTag"
                    name="candidate_module_prefix"
                    value={formik.values["candidate_module_prefix"]}
                    onChange={formik.handleChange}
                    disabled={
                      location?.state?.id &&
                      compRes?.["candidate_module_prefix"]
                    }
                  />
                  {formik.touched.candidate_module_prefix &&
                    formik.errors.candidate_module_prefix && (
                      <div className="err-msg">
                        {formik.errors.candidate_module_prefix}
                      </div>
                    )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Applicant Starts with">
                  <Input
                    placeholder="Applicant Starts with"
                    className="inputTag"
                    name="candidate_module_starts_with"
                    value={formik.values["candidate_module_starts_with"]}
                    disabled={
                      location?.state?.id &&
                      compRes?.["candidate_module_display_starts_with"]
                    }
                    maxLength={15}
                    onChange={(e) => {
                      if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                        return;
                      }
                      formik.setFieldValue(
                        "candidate_module_starts_with",
                        e.target.value
                      );
                    }}
                  />
                  {formik.touched.candidate_module_starts_with &&
                    formik.errors.candidate_module_starts_with && (
                      <div className="err-msg">
                        {formik.errors.candidate_module_starts_with}
                      </div>
                    )}
                </Form.Item>
              </Col>
            </Row>
          </>
          {/* <Title level={5} className="mt2">
            Bank Details
          </Title>
          <Divider />
          <Row className="gap1">
            <Col span={7}>
              <Form.Item label="Account IFSC" required>
                <Input
                  placeholder="Account IFSC"
                  name="account_ifsc"
                  className="inputTag"
                  value={formik.values["account_ifsc"]}
                  onChange={(e) => {
                    if (ifscRef.current) {
                      clearTimeout(ifscRef.current);
                    }
                    ifscRef.current = setTimeout(() => {
                      axios
                        .get(apis.getIfscDetails, {
                          headers: {
                            Authorization:
                              "Bearer " + localStorage.getItem("token"),
                          },
                          params: {
                            ifsc_code: e.target.value?.toUpperCase(),
                          },
                        })
                        .then((res) => {
                          if (!res?.data?.error) {
                            formik.setFieldValue(
                              `bank_name`,
                              res?.data?.data?.bank_name
                            );
                            formik.setFieldValue(
                              `bank_branch`,
                              res?.data?.data?.branch
                            );
                            formik.setFieldValue(
                              `bank_address`,
                              res?.data?.data?.address
                            );
                          } else {
                            formik.setFieldValue(`bank_name`, "");
                            formik.setFieldValue(`bank_branch`, "");
                            formik.setFieldValue(`bank_address`, "");
                            if (e.target.value)
                              message.error(
                                <span className="messageText">
                                  Bank Details not found.
                                </span>
                              );
                          }
                        })
                        .catch(() => {
                          if (e.target.value)
                            message.error(
                              <span className="messageText">
                                Bank Details not found.
                              </span>
                            );
                          formik.setFieldValue(`bank_name`, "");
                          formik.setFieldValue(`bank_branch`, "");
                          formik.setFieldValue(`bank_address`, "");
                        });
                    }, 700);
                    formik.setFieldValue(
                      `account_ifsc`,
                      e.target.value?.toUpperCase()
                    );
                  }}
                />
                {formik.touched.account_ifsc && formik.errors.account_ifsc && (
                  <div className="err-msg">{formik.errors.account_ifsc}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Account name">
                <Input
                  placeholder="Account name"
                  name="account_name"
                  className="inputTag"
                  value={formik.values["account_name"]}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Account Number" required>
                <Input
                  placeholder="Account Number"
                  name="account_number"
                  className="inputTag"
                  value={formik.values["account_number"]}
                  maxLength={18}
                  onChange={formik.handleChange}
                />
                {formik.touched.account_number &&
                  formik.errors.account_number && (
                    <div className="err-msg">
                      {formik.errors.account_number}
                    </div>
                  )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={7}>
              <Form.Item label="Bank name">
                <Input
                  placeholder="Bank name"
                  name="bank_name"
                  className="inputTag"
                  value={formik.values["bank_name"]}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Bank branch">
                <Input
                  placeholder="Bank branch"
                  name="bank_branch"
                  className="inputTag"
                  value={formik.values["bank_branch"]}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Bank address">
                <Input
                  placeholder="Bank address"
                  name="bank_address"
                  className="inputTag"
                  value={formik.values["bank_address"]}
                  onChange={formik.handleChange}
                />
              </Form.Item>
            </Col>
          </Row> */}
          {!["kyc_init"]?.includes(compRes?.kyc_status)
            ? Array.isArray(onboardPreviewResponse?.data) &&
              onboardPreviewResponse?.data?.map(
                (fieldsElement, fieldsIndex) =>
                  ![
                    "Business Address",
                    "Bank Account Details",
                    "Upload Documents",
                  ]?.includes(fieldsElement?.section_title) && (
                    <Row className="gap1">
                      <Title level={5} className="mt2">
                        {fieldsElement?.section_title}
                      </Title>
                      <Divider className="mt0" />
                      {![
                        "Business Address",
                        "Bank Account Details",
                        "Upload Documents",
                      ]?.includes(fieldsElement?.section_title) &&
                        fieldsElement?.fields_list?.map(
                          (listElement, listIndex) => {
                            if (
                              hiddenKeys?.includes(listElement?.entity_column)
                            ) {
                              if (
                                listElement?.data_type == "string" ||
                                listElement?.data_type == "number"
                              ) {
                                return (
                                  <Col span={7}>
                                    <Form.Item
                                      label={listElement?.entity_column_label}
                                    >
                                      <Input
                                        placeholder={
                                          listElement?.entity_column_label
                                        }
                                        name={listElement?.entity_column}
                                        className="inputTag"
                                        value={listElement?.value}
                                        // onChange={formik.handleChange}
                                        disabled={true}
                                      />
                                    </Form.Item>
                                  </Col>
                                );
                              } else if (listElement?.data_type == "dropdown") {
                                return (
                                  <Col span={7}>
                                    <Form.Item
                                      label={listElement?.entity_column_label}
                                    >
                                      <Select
                                        name={listElement?.entity_column}
                                        className="inputTag selectTag"
                                        placeholder={
                                          listElement?.entity_column_label
                                        }
                                        options={listElement?.dropdown_fields}
                                        value={listElement?.value}
                                        disabled={true}
                                        allowClear
                                      />
                                    </Form.Item>
                                  </Col>
                                );
                              } else if (listElement?.data_type == "document") {
                                return (
                                  <Col
                                    span={`${
                                      listElement?.group ==
                                        "Upload Documents" ||
                                      [
                                        "upload_aadhaar_front",
                                        "upload_aadhaar_back",
                                      ]?.includes(listElement?.entity_column)
                                        ? 10
                                        : 17
                                    }`}
                                    className="uploadSection align-items-center mb1"
                                  >
                                    <Text>
                                      {listElement?.entity_column_label}
                                    </Text>
                                    <Dragger
                                      name={listElement?.entity_column}
                                      disabled={true}
                                    >
                                      {listElement?.value ? (
                                        <img
                                          src={
                                            getFileExtension(
                                              listElement?.file_properties
                                                ?.filename
                                            ) == "pdf"
                                              ? png_icon
                                              : listElement?.value
                                          }
                                          alt="avatar"
                                          style={{
                                            width: "150px",
                                            height: "120px",
                                          }}
                                        />
                                      ) : (
                                        <>
                                          <p
                                            style={{
                                              fontSize: "0.8rem !important",
                                            }}
                                            className="ant-upload-text"
                                          >
                                            {"Drag Your image file here"}
                                            <br />
                                            {"or Click here to Upload"}
                                          </p>
                                        </>
                                      )}
                                    </Dragger>
                                    {getFileExtension(
                                      listElement?.file_properties?.filename
                                    )?.toLowerCase() === "pdf" ? (
                                      <>
                                        <div className="active_section d-flex mt1">
                                          <div
                                            className="preview_section"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <Button
                                              icon={<EyeFilled />}
                                              onClick={() => {
                                                setIsPreviewOpen(() => ({
                                                  open: true,
                                                  url: listElement?.value,
                                                  fileType: getFileExtension(
                                                    listElement?.file_properties
                                                      ?.filename
                                                  ),
                                                }));
                                              }}
                                              className="mr1"
                                              sty
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </Col>
                                );
                              } else if (listElement?.data_type == "date") {
                                return (
                                  <Col span={7}>
                                    <Form.Item
                                      label={listElement?.entity_column_label}
                                    >
                                      <Input
                                        placeholder={
                                          listElement?.entity_column_label
                                        }
                                        name={listElement?.entity_column}
                                        className="inputTag"
                                        value={
                                          listElement?.entity_column ==
                                          "year_of_incorporation"
                                            ? moment(
                                                listElement?.value
                                              )?.format("YYYY")
                                            : listElement?.value
                                            ? moment(
                                                listElement?.value
                                              )?.format("DD-MM-YYYY")
                                            : listElement?.value
                                        }
                                        // onChange={formik.handleChange}
                                        disabled={true}
                                      />
                                    </Form.Item>
                                  </Col>
                                );
                              }
                            }
                          }
                        )}
                    </Row>
                  )
              )
            : null}

          {!["kyc_submitted", "kyc_pending"]?.includes(compRes?.kyc_status) ? (
            <div className="d-flex flex-end">
              <Button
                key="2"
                className="cancelButton ml1 mt1"
                onClick={() => history.push("/admin/clients/")}
                style={{ width: "100px" }}
                disabled={addStaffCompRes?.loading}
              >
                Cancel
              </Button>
              <Button
                key="1"
                htmlType="submit"
                className="ant-btn ant-btn-primary pp-main-button mt1 ml2"
                style={{ width: "100px" }}
                disabled={addStaffCompRes?.loading || passwordCheck}
              >
                {addStaffCompRes?.loading
                  ? location?.state?.id
                    ? "Updating..."
                    : "Creating..."
                  : location?.state?.id
                  ? "Update"
                  : "Create"}
              </Button>
            </div>
          ) : null}
        </Form>
      </div>
    </>
  );
};

export default withRouter(OrganisationProfile);
